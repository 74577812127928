import React from "react";
import { Box, Container, SxProps, Theme } from "@mui/material";
import { useLeftAlignPadding } from "../shared/useLeftAlignPadding";
import { scrollbarSx } from "../shared/scrollbarProps";

interface ChatSplitLayoutProps {
    header?: React.ReactNode;
    mainContent: React.ReactNode;
    preview?: React.ReactNode;
    mainContentProps?: SxProps<Theme>;
    scrollRef?: React.RefObject<HTMLDivElement>;
    onScroll?: React.UIEventHandler<HTMLDivElement>;
}

export const ChatSplitLayout: React.FC<ChatSplitLayoutProps> = ({
    header,
    mainContent,
    preview,
    mainContentProps,
    scrollRef,
    onScroll,
}) => {
    const leftAlignPadding = useLeftAlignPadding();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                overflowY: "hidden",
                rowGap: 1,
            }}
        >
            {header && (
                <Box sx={{ bgcolor: "primary.main", borderRadius: 3, border: 1, borderColor: "neutrals.30" }}>
                    {header}
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    height: "100%",
                    overflowY: "hidden",
                    columnGap: 1,
                    maxWidth: "100%",
                    minWidth: 900,
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <Box
                    ref={scrollRef}
                    onScroll={onScroll}
                    sx={[
                        {
                            display: "flex",
                            flexDirection: "column",
                            pt: 3,
                            // pb: 2,
                            overflowY: "auto",
                            overflowX: "hidden",
                            width: preview ? "60%" : "100%",
                            minWidth: 350,
                            borderRadius: 3,
                            ...scrollbarSx,
                        },
                        // Combine with any additional styling passed via mainContentProps
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        ...(Array.isArray(mainContentProps) ? mainContentProps : [mainContentProps]),
                    ]}
                >
                    <Container
                        sx={{
                            px: `${leftAlignPadding} !important`,
                            pt: 0,
                            // mb: 3,
                            maxWidth: preview == null ? "1100px !important" : undefined,
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 3,
                            // maxWidth: preview != null ? "md" : undefined,
                        }}
                    >
                        {mainContent}
                    </Container>
                </Box>
                {preview && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "40%",
                            minWidth: 410,
                            overflowY: "auto",
                            overflowX: "hidden",
                            borderRadius: 3,
                            border: 1,
                            borderColor: "neutrals.30",
                            bgcolor: "primary.main",
                            ...scrollbarSx,
                        }}
                    >
                        {preview}
                    </Box>
                )}
            </Box>
        </Box>
    );
};
