import * as React from "react";
import { Box, Typography, Button, Stack, Chip, useTheme } from "@mui/material";
import { ArrowRight2, Copy, TickCircle } from "iconsax-react";
import { PersonaListSchema } from "../backend-client/generated";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
interface PersonaCardProps {
    persona: PersonaListSchema;
    onSelect: (id: string, type: PersonaListSchema["type"]) => void;
    onDuplicate: (id: string) => void;
    onPreview: (id: string) => void;
    isSelected: boolean;
    isReadOnly?: boolean;
}

export const PersonaCard = React.memo(
    ({ persona, isSelected, onSelect, onDuplicate, onPreview, isReadOnly = false }: PersonaCardProps) => {
        const handleSelect = React.useCallback(
            () => onSelect(persona.unique_id, persona.type),
            [onSelect, persona.unique_id, persona.type],
        );
        const handleDuplicate = React.useCallback(
            () => onDuplicate(persona.unique_id),
            [onDuplicate, persona.unique_id],
        );
        const handlePreview = React.useCallback(() => onPreview(persona.unique_id), [onPreview, persona.unique_id]);

        const theme = useTheme();

        return (
            <Box
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    border: 1,
                    borderColor: "neutrals.20",
                    borderRadius: 1,
                    height: 220,
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <Chip
                        // label={persona.tags[0]}
                        label={
                            persona.unique_id === DEFAULT_PERSONA_ID
                                ? "Default"
                                : persona.type === "tenant"
                                  ? "Predefined"
                                  : "Custom"
                        }
                        size="small"
                        sx={{ alignSelf: "flex-start", bgcolor: "surface.75", typography: "caption" }}
                    />
                    <Typography variant="body2">{persona.name}</Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
                        {(persona.description?.trim().length ?? 0) > 0 ? persona.description : "No description"}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={1} sx={{ mt: 0.5, "& > :last-child": { ml: "auto !important" } }}>
                    {(!isReadOnly || isSelected) && (
                        <Button
                            variant="outlined"
                            onClick={handleSelect}
                            disabled={isReadOnly}
                            startIcon={
                                <TickCircle
                                    size={16}
                                    variant={isSelected ? "Bold" : "Outline"}
                                    color={isSelected ? theme.palette.secondary.main : theme.palette.neutrals[80]}
                                />
                            }
                            size="small"
                            sx={{
                                color: isSelected ? "secondary.main" : "neutrals.80",
                                borderColor: "neutrals.30",
                                bgcolor: isSelected ? theme.palette.secondary.light : "transparent",
                                "& .MuiButton-startIcon": {
                                    mr: 0.75,
                                },
                                "&:hover": {
                                    bgcolor: isSelected ? theme.palette.secondary.light : "action.hover",
                                    borderColor: "neutrals.30",
                                    cursor: isSelected || isReadOnly ? "default" : "pointer",
                                },
                                "&.Mui-disabled": {
                                    bgcolor: isSelected ? theme.palette.secondary.light : "transparent",
                                    borderColor: "neutrals.30",
                                    opacity: 1,
                                },
                            }}
                        >
                            <Typography variant="caption" color={isSelected ? "secondary.main" : "neutrals.80"}>
                                {isSelected ? "Active" : "Select"}
                            </Typography>
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        onClick={handleDuplicate}
                        startIcon={<Copy size={16} color={theme.palette.neutrals[80]} />}
                        size="small"
                        sx={{
                            color: "neutrals.80",
                            borderColor: "neutrals.30",
                            "& .MuiButton-startIcon": { mr: 0.75 },
                            "&:hover": {
                                bgcolor: "action.hover",
                            },
                        }}
                    >
                        <Typography variant="caption" color="neutrals.80">
                            Duplicate
                        </Typography>
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={handlePreview}
                        endIcon={<ArrowRight2 size={16} color={theme.palette.neutrals[80]} />}
                        size="small"
                        sx={{
                            color: "neutrals.80",
                            borderColor: "neutrals.30",
                            minWidth: "96px",
                            "& .MuiButton-endIcon": {
                                ml: 0.75,
                            },
                            "&:hover": {
                                bgcolor: "action.hover",
                            },
                        }}
                    >
                        <Typography variant="caption" color="neutrals.80">
                            Preview
                        </Typography>
                    </Button>
                </Stack>
            </Box>
        );
    },
);

PersonaCard.displayName = "PersonaCard";
