import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip } from "@mui/material";
import { ArrowUp, Paperclip } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { getScrollbarSx } from "../shared/scrollbarProps";

interface ChatInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    onAttach?: () => void;
    sx?: SxProps<Theme>;
}

export const ChatInput: React.FC<ChatInputProps> = ({
    query,
    onQueryChange,
    onQuerySubmit,
    onAttach,
    disabledReason,
    sx,
}) => {
    const theme = useTheme();

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "white",
                    borderRadius: 2,
                    border: 1,
                    borderColor: "neutrals.25",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Input
                autoFocus
                placeholder="Message AnswerGrid"
                value={query}
                onChange={onQueryChange}
                onKeyDown={handleKeyDown}
                fullWidth
                disableUnderline
                multiline
                maxRows={8}
                sx={{
                    px: 1.5,
                    pt: 1,
                    pb: 0.5,
                    "& .MuiInputBase-input": {
                        ...getScrollbarSx("white"),
                    },
                }}
            />
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                    title={
                        onAttach == null
                            ? "You can only attach files to your first message. To attach new files, start a new chat"
                            : ""
                    }
                >
                    <span>
                        <IconButton
                            size="small"
                            disableRipple
                            disabled={onAttach == null}
                            sx={{ ml: 1, "&:hover": { backgroundColor: "transparent" } }}
                            onClick={onAttach}
                        >
                            <Paperclip
                                size={24}
                                color={onAttach == null ? theme.palette.neutrals[60] : theme.palette.neutrals[80]}
                            />
                        </IconButton>
                    </span>
                </Tooltip>
                <Box sx={{ flex: 1 }} />
                <Tooltip title={disabledReason || ""}>
                    <span>
                        <IconButton sx={{ p: 1 }} disabled={disabledReason != null} onClick={onQuerySubmit}>
                            <Box
                                sx={{
                                    bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 24,
                                    height: 24,
                                }}
                            >
                                <ArrowUp size={24} color={theme.palette.common.white} />
                            </Box>
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};
