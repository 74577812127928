import * as React from "react";
import {
    Box,
    Typography,
    LinearProgress,
    Breadcrumbs,
    Link,
    useTheme,
    IconButton,
    Menu,
    MenuItem,
    TextField,
    CircularProgress,
    SxProps,
} from "@mui/material";
import { Folder, Trash, Add } from "iconsax-react";
import { useCallback, useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    coreFileStorageRouterMoveFile,
    coreFileStorageRouterDeleteFile,
    coreFileStorageRouterCreateFolder,
} from "../backend-client/generated/services.gen";
import { UserUploadedFileSchema } from "../backend-client/generated/types.gen";
import PDFIcon from "../assets/pdf-icon.svg";
import DocxIcon from "../assets/docx-icon.png";
import { getFilesQueryOptions } from "./queryOptions";
import { SelectedFile } from "./focusDocuments";

type FolderPath = {
    id: string;
    name: string;
};

interface DriveExplorerProps {
    currentFolderId: string | null;
    onSelectFile: ((file: SelectedFile) => void) | undefined;
    onDeleteFile?: ((fileId: string) => void) | undefined;
    setCurrentFolderId: React.Dispatch<React.SetStateAction<string | null>>;
    sx?: SxProps;
    filesSx?: SxProps;
}

export const DriveExplorer: React.FC<DriveExplorerProps> = ({
    currentFolderId,
    onSelectFile,
    onDeleteFile,
    setCurrentFolderId,
    sx,
    filesSx,
}) => {
    const queryClient = useQueryClient();
    const [folderPath, setFolderPath] = useState<FolderPath[]>([]);
    const [contextMenu, setContextMenu] = useState<{ x: number; y: number } | null>(null);
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [newFolderName, setNewFolderName] = useState("");

    const filesQueryOptions = useMemo(() => getFilesQueryOptions(currentFolderId ?? undefined), [currentFolderId]);

    const filesQuery = useQuery(filesQueryOptions);

    const { mutateAsync: moveFile } = useMutation({
        mutationFn: (params: { fileId: string; folderId: string | null }) =>
            coreFileStorageRouterMoveFile({
                path: { file_id: params.fileId },
                body: { folder_id: params.folderId },
            }),
        onSuccess: () => {
            // Invalidate queries to refetch the file list
            void queryClient.invalidateQueries({ queryKey: ["files"] });
        },
    });

    const { mutateAsync: deleteFile } = useMutation({
        mutationFn: (fileId: string) =>
            coreFileStorageRouterDeleteFile({
                path: { file_id: fileId },
            }),
        onMutate: async (fileId: string) => {
            await queryClient.cancelQueries({ queryKey: ["files"] });

            const previousFiles = queryClient.getQueryData(filesQueryOptions.queryKey);

            queryClient.setQueryData(filesQueryOptions.queryKey, old => {
                if (!old?.data) return old;
                return {
                    ...old,
                    data: {
                        ...old.data,
                        files: old.data.files.filter(file => file.unique_id !== fileId),
                    },
                    error: undefined,
                };
            });

            return { previousFiles };
        },
        onError: (err, fileId, context) => {
            queryClient.setQueryData(filesQueryOptions.queryKey, context?.previousFiles);
        },
        onSettled: () => {
            void queryClient.invalidateQueries({ queryKey: ["files"] });
        },
    });

    const { mutateAsync: createFolder, isPending: isLoadingCreatingFolder } = useMutation({
        mutationFn: (name: string) =>
            coreFileStorageRouterCreateFolder({
                body: { name },
            }),
        onSuccess: () => {
            void queryClient.invalidateQueries({ queryKey: ["files"] });
        },
    });

    const handleFolderClick = useCallback(
        (folderId: string, folderName: string) => {
            setCurrentFolderId(folderId);
            setFolderPath(prev => [...prev, { id: folderId, name: folderName }]);
        },
        [setCurrentFolderId],
    );

    const handleFileClick = useCallback(
        (file: UserUploadedFileSchema) => {
            const fileType = file.file_type;
            onSelectFile?.({
                id: file.unique_id,
                name: file.file_name ?? file.blob_name,
                fileType,
                isIndexing: file.is_indexing,
            });
        },
        [onSelectFile],
    );

    const handleBreadcrumbClick = useCallback(
        (index: number) => {
            if (index === -1) {
                setCurrentFolderId(null);
                setFolderPath([]);
            } else {
                const newPath = folderPath.slice(0, index + 1);
                setCurrentFolderId(newPath[newPath.length - 1].id);
                setFolderPath(newPath);
            }
        },
        [folderPath, setCurrentFolderId],
    );

    const handleDragStart = useCallback((e: React.DragEvent, fileId: string) => {
        e.dataTransfer.setData("fileId", fileId);
    }, []);

    const handleFolderDrop = useCallback(
        (e: React.DragEvent, folderId: string) => {
            e.preventDefault();
            const fileId = e.dataTransfer.getData("fileId");
            if (fileId) {
                void moveFile({ fileId, folderId });
            }
        },
        [moveFile],
    );

    const handleBreadcrumbDrop = useCallback(
        (e: React.DragEvent, index: number) => {
            e.preventDefault();
            const fileId = e.dataTransfer.getData("fileId");
            if (fileId) {
                const targetFolderId = index === -1 ? null : folderPath[index]?.id;
                void moveFile({ fileId, folderId: targetFolderId });
            }
        },
        [moveFile, folderPath],
    );

    const handleDeleteFile = useCallback(
        async (e: React.MouseEvent, fileId: string) => {
            e.stopPropagation();
            if (window.confirm("Are you sure you want to delete this file? This action cannot be undone.")) {
                try {
                    await deleteFile(fileId);
                    onDeleteFile?.(fileId);
                } catch (error) {
                    console.error("Error deleting file:", error);
                }
            }
        },
        [deleteFile, onDeleteFile],
    );

    const handleContextMenu = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        // If the click target is the main container or DialogContent, show the menu
        if (e.target === e.currentTarget) {
            setContextMenu({ x: e.clientX, y: e.clientY });
        }
    }, []);

    const handleCloseContextMenu = useCallback(() => {
        setContextMenu(null);
    }, []);

    const handleCreateFolder = useCallback(() => {
        setIsCreatingFolder(true);
        setNewFolderName("");
        handleCloseContextMenu();
    }, [handleCloseContextMenu]);

    const handleNewFolderNameSubmit = useCallback(async () => {
        if (newFolderName.trim()) {
            try {
                await createFolder(newFolderName.trim());
                setIsCreatingFolder(false);
                setNewFolderName("");
            } catch (error) {
                console.error("Error creating folder:", error);
            }
        }
    }, [createFolder, newFolderName]);

    const handleNewFolderNameKeyDown = useCallback(
        (e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
                void handleNewFolderNameSubmit();
            } else if (e.key === "Escape") {
                setIsCreatingFolder(false);
                setNewFolderName("");
            }
        },
        [handleNewFolderNameSubmit],
    );

    const theme = useTheme();

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    mb: 3,
                    // Add minHeight to ensure the box is clickable even when empty
                    minHeight: 150,
                    maxHeight: "40vh",
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            onContextMenu={handleContextMenu}
        >
            <Breadcrumbs sx={{ px: 1 }}>
                <Link
                    component="button"
                    variant="body2"
                    onClick={() => handleBreadcrumbClick(-1)}
                    onDragOver={e => e.preventDefault()}
                    onDrop={e => handleBreadcrumbDrop(e, -1)}
                    sx={{ cursor: "pointer", color: "secondary.main" }}
                >
                    Home folder
                </Link>
                {folderPath.map((folder, index) => (
                    <Link
                        key={folder.id}
                        component="button"
                        variant="body2"
                        onClick={() => handleBreadcrumbClick(index)}
                        onDragOver={e => e.preventDefault()}
                        onDrop={e => handleBreadcrumbDrop(e, index)}
                        sx={{ cursor: "pointer", color: "secondary.main" }}
                        noWrap
                    >
                        {folder.name}
                    </Link>
                ))}
            </Breadcrumbs>

            {filesQuery.isLoading ? (
                <Box sx={{ p: 2, textAlign: "center" }}>
                    <LinearProgress />
                </Box>
            ) : filesQuery.data?.data?.folders.length === 0 && filesQuery.data?.data?.files.length === 0 ? (
                <Typography variant="body2" color="neutrals.80">
                    No files found
                </Typography>
            ) : (
                <Box
                    sx={[
                        {
                            display: "flex",
                            flexDirection: "column",
                            gap: 0.5,
                            maxHeight: "40vh",
                            overflowY: "auto",
                        },
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        ...(Array.isArray(filesSx) ? filesSx : [filesSx]),
                    ]}
                >
                    {isCreatingFolder && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                p: 1,
                                borderRadius: 1,
                            }}
                        >
                            {!isLoadingCreatingFolder ? (
                                <Folder size={20} color={theme.palette.neutrals[60]} />
                            ) : (
                                <CircularProgress size={20} />
                            )}
                            <TextField
                                autoFocus
                                size="small"
                                value={newFolderName}
                                onChange={e => setNewFolderName(e.target.value)}
                                onKeyDown={handleNewFolderNameKeyDown}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onBlur={handleNewFolderNameSubmit}
                                placeholder="New folder name"
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        height: 32,
                                    },
                                }}
                            />
                        </Box>
                    )}
                    {filesQuery.data?.data?.folders.map(folder => (
                        <Box
                            key={folder.unique_id}
                            onClick={() => handleFolderClick(folder.unique_id, folder.name)}
                            onDragOver={e => e.preventDefault()}
                            onDrop={e => handleFolderDrop(e, folder.unique_id)}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                p: 1,
                                borderRadius: 1,
                                cursor: "pointer",
                                "&:hover": {
                                    bgcolor: "surface.25",
                                },
                                "&.drag-over": {
                                    bgcolor: "surface.50",
                                },
                            }}
                        >
                            <Folder size={20} color={theme.palette.neutrals[60]} />
                            <Typography variant="body2" noWrap>
                                {folder.name}
                            </Typography>
                            <Typography variant="caption" sx={{ ml: "auto", color: "neutrals.60" }} noWrap>
                                {folder.file_count} files
                            </Typography>
                        </Box>
                    ))}

                    {filesQuery.data?.data?.files.map(file => (
                        <Box
                            key={file.unique_id}
                            onClick={onSelectFile != null ? () => handleFileClick(file) : undefined}
                            draggable
                            onDragStart={e => handleDragStart(e, file.unique_id)}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                p: 1,
                                borderRadius: 1,
                                cursor: onSelectFile != null ? "pointer" : "default",
                                position: "relative",
                                "&:hover": {
                                    bgcolor: "surface.25",
                                    "& .delete-button": {
                                        opacity: 1,
                                    },
                                },
                            }}
                        >
                            <Box
                                component="img"
                                src={file.file_type === "pdf" ? PDFIcon : DocxIcon}
                                alt={`${file.file_type?.toUpperCase()} icon`}
                                sx={{
                                    height: 20,
                                    width: 20,
                                }}
                            />
                            <Typography variant="body2" noWrap sx={{ flex: 1 }}>
                                {file.file_name ?? file.blob_name}
                            </Typography>
                            <IconButton
                                className="delete-button"
                                size="small"
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={e => handleDeleteFile(e, file.unique_id)}
                            >
                                <Trash size={16} color={theme.palette.neutrals[60]} />
                            </IconButton>
                        </Box>
                    ))}
                </Box>
            )}

            <Menu
                open={Boolean(contextMenu)}
                onClose={handleCloseContextMenu}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu ? { top: contextMenu.y, left: contextMenu.x } : undefined}
            >
                <MenuItem onClick={handleCreateFolder}>
                    <Add size={16} style={{ marginRight: 8 }} />
                    <Typography variant="caption">New Folder</Typography>
                </MenuItem>
            </Menu>
        </Box>
    );
};
