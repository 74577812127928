import * as React from "react";
import { IconButton } from "@mui/material";
import { ArrowLeft2, ArrowRight2 } from "iconsax-react";

interface NavigationButtonProps {
    direction: "left" | "right";
    onClick: () => void;
    disabled?: boolean;
    sx?: object;
}

export const NavigationButton = React.memo(({ direction, onClick, disabled, sx }: NavigationButtonProps) => {
    const Icon = direction === "left" ? ArrowLeft2 : ArrowRight2;

    return (
        <IconButton
            onClick={onClick}
            disabled={disabled}
            sx={{
                color: "secondary.main",
                bgcolor: "white",
                ...sx,
            }}
        >
            <Icon size={20} />
        </IconButton>
    );
});

NavigationButton.displayName = "NavigationButton";
