import * as React from "react";
import { Box, Typography, useTheme, Tooltip } from "@mui/material";
import { InfoCircle } from "iconsax-react";

export const SearchViewHeader: React.FC<{ text: string; tooltip?: string; action?: React.ReactNode }> = ({
    text,
    tooltip,
    action,
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", overflow: "hidden" }}>
                <Typography variant="h6" gutterBottom sx={{ color: "secondary.main" }} noWrap>
                    {text}
                </Typography>
                {tooltip != null && (
                    <Tooltip title={tooltip}>
                        <span style={{ marginLeft: theme.spacing(1) }}>
                            <InfoCircle size={16} color={theme.palette.neutrals[50]} />
                        </span>
                    </Tooltip>
                )}
            </Box>
            {action}
        </Box>
    );
};
