import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import "./App.css";

declare module "@mui/material/styles" {
    interface Palette {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            20: string;
            10: string;
            5: string;
        };
        selection: {
            main: string;
        };
    }

    interface PaletteOptions {
        surface: {
            100: string;
            75: string;
            50: string;
            25: string;
        };
        neutrals: {
            100: string;
            90: string;
            80: string;
            70: string;
            60: string;
            50: string;
            40: string;
            30: string;
            25: string;
            20: string;
            10: string;
            5: string;
        };
        selection: {
            main: string;
        };
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            light: "#98A2B3",
            main: "#F8F8F7",
        },
        secondary: {
            light: "#EFF2ED",
            main: "#365934",
        },
        success: {
            main: "#7AB143",
        },
        error: {
            main: red.A400,
        },
        surface: {
            100: "#E3DAC8",
            75: "#F0EDE6",
            50: "#F4F2EF",
            25: "#F8F8F7",
        },
        neutrals: {
            100: "#101323",
            90: "#1D2939",
            80: "#344054",
            70: "#475467",
            60: "#667085",
            50: "#98A2B3",
            40: "#D0D5DD",
            30: "#E7E6E4",
            25: "#F0F0EF",
            20: "#F2F4F7",
            10: "#F9FAFB",
            5: "#FCFCFD",
        },
        selection: {
            main: "#669461",
        },
        text: {
            primary: "#344054",
        },
    },
    typography: {
        fontFamily: ["'Inter'", "sans-serif"].join(","),
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    borderRadius: 16,
                },
            },
        },
    },
});
