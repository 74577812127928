export const getScrollbarSx = (color: string) => ({
    "&::-webkit-scrollbar": {
        width: "14px",
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#98A2B3",
        borderRadius: "8px",
        border: "3px solid #F4F2EF",
        borderColor: "primary.main",
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: color,
        borderTopRightRadius: "12px",
        borderBottomRightRadius: "12px",
    },
});

export const scrollbarSx = getScrollbarSx("primary.main");
