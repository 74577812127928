import { ChatHeader } from "./chatHeader";
import { TenantSchema } from "../backend-client/generated/types.gen";
import { ChatHeaderWithExtraFeatures } from "./chatHeaderWithExtraFeatures";
import * as React from "react";
import { SelectedPersona } from "./selectedPersona";
import { SelectedFile } from "./focusDocuments";

interface ChatHeaderForTenantProps {
    tenant: TenantSchema | undefined;
    chatId: string | undefined;
    chatName: string | undefined;
    isLoading: boolean;
    // TODO: Render the tenant focused documents, not just user ones
    focusedDocuments: Array<SelectedFile>;
    onFocusedDocumentsChange: ((docs: Array<SelectedFile>) => void) | undefined;
    selectedPersona: SelectedPersona;
    onSelectedPersonaChange: React.Dispatch<React.SetStateAction<SelectedPersona>> | undefined;
}

export const ChatHeaderForTenant: React.FC<ChatHeaderForTenantProps> = ({
    tenant,
    chatId,
    chatName,
    isLoading,
    focusedDocuments,
    onFocusedDocumentsChange,
    selectedPersona: selectedPersonaId,
    onSelectedPersonaChange,
}) => {
    if (tenant?.can_chat_with_docs) {
        return isLoading ? (
            <ChatHeaderWithExtraFeatures
                chatId={chatId}
                chatName={undefined}
                focusedDocuments={focusedDocuments}
                onFocusedDocumentsChange={onFocusedDocumentsChange}
                selectedPersona={selectedPersonaId}
                onSelectedPersonaChange={onSelectedPersonaChange}
            />
        ) : (
            <ChatHeaderWithExtraFeatures
                chatId={chatId}
                chatName={chatName ?? "Untitled Chat"}
                focusedDocuments={focusedDocuments}
                onFocusedDocumentsChange={onFocusedDocumentsChange}
                selectedPersona={selectedPersonaId}
                onSelectedPersonaChange={onSelectedPersonaChange}
            />
        );
    }

    return isLoading ? (
        <ChatHeader chatId={chatId} chatName={undefined} />
    ) : (
        <ChatHeader chatId={chatId} chatName={chatName ?? "Untitled Chat"} />
    );
};
