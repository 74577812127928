import * as React from "react";
import { Dialog, DialogTitle, DialogContent, Box, Typography, Fade } from "@mui/material";
import { DocumentUpload, DocumentText, TickCircle } from "iconsax-react";
import { useState, useMemo, useCallback } from "react";
import { DriveExplorer } from "./driveExplorer";
import { UploadTab } from "./UploadTab";
import { useUploadFileMutation } from "./uploadFileMutation";
import { unique } from "../utils/arrays";

type TabType = "upload" | "drive";

const EMPTY_FILE_IDS: string[] = [];

interface DriveExplorerDialogProps {
    open: boolean;
    onClose: () => void;
}

export const DriveExplorerDialog: React.FC<DriveExplorerDialogProps> = ({ open, onClose }) => {
    const [activeTab, setActiveTab] = useState<TabType>("drive");
    const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [uploadSuccess, setUploadSuccess] = useState(false);

    const tabs = useMemo(
        () => [
            { id: "drive" as const, label: "Drive", icon: <DocumentText size={20} /> },
            { id: "upload" as const, label: "Upload", icon: <DocumentUpload size={20} /> },
        ],
        [],
    );

    const { mutateAsync: uploadFile } = useUploadFileMutation();

    const getSuccessMessage = useMemo(
        () => (files: File[]) => {
            if (files.length === 0) return "";
            if (files.length === 1) {
                return files[0].name.length > 30 ? `${files[0].name.slice(0, 27)}...` : files[0].name;
            }
            return `${files.length} files`;
        },
        [],
    );

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

    const handleUploadFiles = useCallback(
        async (files: File[]) => {
            setIsUploading(true);
            setUploadProgress(0);
            setUploadError(null);
            setUploadSuccess(false);
            setUploadedFiles(files);

            try {
                const progressInterval = setInterval(() => {
                    setUploadProgress(prev => {
                        if (prev >= 95) return prev;
                        const increment = Math.max(0.5, (100 - prev) * 0.05);
                        return Math.min(95, prev + increment);
                    });
                }, 200);

                await Promise.all(
                    // TODO: HACK since we don't have unique ids for files downstream
                    unique(files, file => file.name).map(async file => {
                        const result = await uploadFile({
                            file,
                            lastModified: file.lastModified.toString(),
                        });
                        if (result.data == null) {
                            throw new Error("Failed to upload file");
                        }
                        return result.data.file.unique_id;
                    }),
                );

                clearInterval(progressInterval);
                setUploadProgress(100);
                setUploadSuccess(true);
            } catch (error) {
                console.error("Error uploading files:", error);
                setUploadError("Failed to upload files. Please try again.");
            } finally {
                setIsUploading(false);
                setTimeout(() => {
                    setUploadProgress(0);
                    setUploadSuccess(false);
                }, 3000);
            }
        },
        [uploadFile],
    );

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ pb: 2 }}>Drive Explorer</DialogTitle>
            <Box
                sx={{
                    px: 3,
                    display: "flex",
                    gap: 2,
                    borderBottom: 1,
                    borderColor: "neutrals.20",
                    justifyContent: "center",
                }}
            >
                {tabs.map(tab => (
                    <Box
                        key={tab.id}
                        onClick={() => setActiveTab(tab.id)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            py: 1,
                            px: 2,
                            cursor: "pointer",
                            borderBottom: 2,
                            borderTopLeftRadius: 2,
                            borderTopRightRadius: 2,
                            borderColor: activeTab === tab.id ? "primary.main" : "transparent",
                            color: activeTab === tab.id ? "neutrals.80" : "neutrals.70",
                            bgcolor: activeTab === tab.id ? "surface.25" : "transparent",
                        }}
                    >
                        {tab.icon}
                        <Typography variant="body2">{tab.label}</Typography>
                    </Box>
                ))}
            </Box>
            <DialogContent
                sx={{
                    height: "70vh",
                    minHeight: 400,
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                }}
            >
                {activeTab === "upload" && (
                    <>
                        <UploadTab
                            isUploading={isUploading}
                            uploadProgress={uploadProgress}
                            uploadError={uploadError}
                            indexingFileIds={EMPTY_FILE_IDS}
                            onUploadFiles={handleUploadFiles}
                        />
                        <Fade in={uploadSuccess}>
                            <Box
                                sx={{
                                    position: "absolute",
                                    bottom: 24,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                    bgcolor: "secondary.main",
                                    color: "white",
                                    py: 1,
                                    px: 2,
                                    borderRadius: 1,
                                    maxWidth: "80%",
                                }}
                            >
                                <TickCircle size={20} variant="Bold" />
                                <Typography variant="body2" noWrap>
                                    {`${getSuccessMessage(uploadedFiles)} uploaded successfully!`}
                                </Typography>
                            </Box>
                        </Fade>
                    </>
                )}

                {activeTab === "drive" && (
                    <DriveExplorer
                        currentFolderId={currentFolderId}
                        onSelectFile={undefined}
                        setCurrentFolderId={setCurrentFolderId}
                        sx={{ maxHeight: "100%" }}
                        filesSx={{ maxHeight: "100%" }}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};
