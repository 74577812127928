import * as React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { coreChatRouterCreateChatFromShare } from "../backend-client/generated/services.gen";
import { NewChatView } from "./newChatView";

export const SharedChatView: React.FC = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const navigate = useNavigate();

    const { mutate: createFromShare, isPending } = useMutation({
        mutationFn: async () => {
            if (!chatId) throw new Error("No chat ID provided");
            return await coreChatRouterCreateChatFromShare({
                path: { chat_id: chatId },
            });
        },
        onSuccess: data => {
            if (data.data?.unique_id == null) {
                throw new Error("No chat ID returned from server");
            }
            navigate(`/chat/${data.data?.unique_id}`);
        },
    });

    const handleProceed = React.useCallback(() => {
        createFromShare();
    }, [createFromShare]);

    const handleCancel = React.useCallback(() => {
        navigate("/");
    }, [navigate]);

    const dialogContent = React.useMemo(
        () => ({
            title: "Shared Chat Access",
            message:
                "You've accessed a shared chat link from a colleague. Click “Proceed” to copy the chat to your account and view it.",
        }),
        [],
    );

    return (
        <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
            <NewChatView />
            <Dialog open={true} maxWidth="sm" fullWidth>
                <DialogTitle>{dialogContent.title}</DialogTitle>
                <DialogContent>{dialogContent.message}</DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button onClick={handleCancel} color="inherit" disabled={isPending}>
                        Cancel
                    </Button>
                    <Button onClick={handleProceed} variant="contained" color="secondary" disabled={isPending}>
                        {isPending ? "Copying…" : "Proceed"}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};
