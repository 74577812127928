import * as React from "react";
import { Box, Typography, Menu, MenuItem, IconButton } from "@mui/material";
import { Notepad, GlobalSearch, ArrowDown2, IconProps } from "iconsax-react";
import { FocusDocumentsDialog } from "./FocusDocumentsDialog";
import { SelectedFile } from "./focusDocuments";

interface ContextSwitcherProps {
    focusedDocuments: Array<SelectedFile>;
    onFocusedDocumentsChange: ((docs: Array<SelectedFile>) => void) | undefined;
}

const contextInfo: Record<"Global" | "Focused", { label: string }> = {
    Global: { label: "All files" },
    Focused: { label: "Focused" },
};

interface ContextIconProps extends IconProps {
    context: "Global" | "Focused";
}

const ContextIcon: React.FC<ContextIconProps> = ({ context, ...props }) => {
    switch (context) {
        case "Global":
            return <GlobalSearch {...props} />;
        case "Focused":
            return <Notepad {...props} />;
    }
};

export const ContextSwitcher: React.FC<ContextSwitcherProps> = React.memo(
    ({ focusedDocuments, onFocusedDocumentsChange }: ContextSwitcherProps) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const [isDialogOpen, setIsDialogOpen] = React.useState(false);

        const currentContext = focusedDocuments.length > 0 ? "Focused" : "Global";

        const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        }, []);

        const handleClose = React.useCallback(() => {
            setAnchorEl(null);
        }, []);

        const canEditFocus = onFocusedDocumentsChange != null;

        const handleSelect = React.useCallback(
            (context: "Global" | "Focused") => {
                if (context === "Focused" && currentContext === "Global" && canEditFocus) {
                    setIsDialogOpen(true);
                } else {
                    onFocusedDocumentsChange?.(context === "Focused" ? focusedDocuments : []);
                }
                handleClose();
            },
            [currentContext, handleClose, onFocusedDocumentsChange, focusedDocuments, canEditFocus],
        );

        const handleSelectGlobal = React.useCallback(() => {
            handleSelect("Global");
        }, [handleSelect]);

        const handleSelectFocused = React.useCallback(() => {
            handleSelect("Focused");
        }, [handleSelect]);

        const handleDialogClose = React.useCallback(() => {
            setIsDialogOpen(false);
        }, []);

        const handleDialogDone = React.useCallback(
            (selectedFiles: SelectedFile[]) => {
                if (selectedFiles.length > 0) {
                    onFocusedDocumentsChange?.(selectedFiles);
                }
                setIsDialogOpen(false);
            },
            [onFocusedDocumentsChange],
        );

        return (
            <>
                <Box
                    onClick={canEditFocus ? handleClick : undefined}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        bgcolor: "common.white",
                        py: 0.5,
                        pl: 1,
                        pr: 0.5,
                        borderRadius: 2,
                        cursor: canEditFocus ? "pointer" : "default",
                        border: 1,
                        borderColor: "neutrals.30",
                        width: 170,
                        height: 32,
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <ContextIcon context={currentContext} size={16} />
                        <Typography variant="caption" fontWeight={500}>
                            Context
                        </Typography>
                        <Typography variant="caption" noWrap>
                            {contextInfo[currentContext].label}
                        </Typography>
                    </Box>
                    {canEditFocus && (
                        <IconButton size="small" disableRipple disableFocusRipple disableTouchRipple>
                            <ArrowDown2 size={12} />
                        </IconButton>
                    )}
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{ sx: { py: 0.5 } }}
                >
                    <MenuItem
                        onClick={handleSelectGlobal}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "caption",
                            pl: 1.5,
                            cursor: canEditFocus ? "pointer" : "default",
                            "&:hover": {
                                bgcolor: canEditFocus ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!canEditFocus}
                        disableTouchRipple={!canEditFocus}
                    >
                        <ContextIcon context="Global" size={16} />
                        {contextInfo.Global.label}
                    </MenuItem>
                    <MenuItem
                        onClick={handleSelectFocused}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            typography: "caption",
                            pl: 1.5,
                            cursor: canEditFocus ? "pointer" : "default",
                            "&:hover": {
                                bgcolor: canEditFocus ? undefined : "transparent",
                            },
                        }}
                        disableRipple={!canEditFocus}
                        disableTouchRipple={!canEditFocus}
                    >
                        <ContextIcon context="Focused" size={16} />
                        {contextInfo.Focused.label}
                    </MenuItem>
                </Menu>
                <FocusDocumentsDialog
                    open={isDialogOpen}
                    onClose={handleDialogClose}
                    onDone={handleDialogDone}
                    selectedFiles={focusedDocuments}
                />
            </>
        );
    },
);

ContextSwitcher.displayName = "ContextSwitcher";
