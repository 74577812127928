import * as React from "react";
import { Box, Container, SxProps, Theme } from "@mui/material";
import { useLeftAlignPadding } from "./useLeftAlignPadding";
import { scrollbarSx } from "./scrollbarProps";

interface SplitLayoutProps {
    header?: React.ReactNode;
    mainContent: React.ReactNode;
    preview?: React.ReactNode;
    mainContentProps?: SxProps<Theme>;
}

export const SplitLayout: React.FC<SplitLayoutProps> = ({ header, mainContent, preview, mainContentProps }) => {
    const leftAlignPadding = useLeftAlignPadding();

    if (preview != null) {
        return (
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    height: "100%",
                    overflowY: "hidden",
                    rowGap: 1,
                }}
            >
                {header && (
                    <Box sx={{ bgcolor: "primary.main", borderRadius: 3, border: 1, borderColor: "neutrals.30" }}>
                        {header}
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: 1,
                        height: "100%",
                        overflowY: "hidden",
                        columnGap: 1,
                        maxWidth: "100%",
                        minWidth: 1000,
                    }}
                >
                    <Box
                        sx={[
                            {
                                display: "flex",
                                flexDirection: "column",
                                py: 3,
                                overflowY: "auto",
                                width: "60%",
                                minWidth: 350,
                                overflowX: "hidden",
                                ...scrollbarSx,
                            },
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                            ...(Array.isArray(mainContentProps) ? mainContentProps : [mainContentProps]),
                        ]}
                    >
                        <Container sx={{ px: `${leftAlignPadding} !important`, py: 0 }}>{mainContent}</Container>
                    </Box>
                    <Box
                        sx={{
                            overflowY: "auto",
                            width: "40%",
                            overflowX: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            borderRadius: 3,
                            border: 1,
                            borderColor: "neutrals.30",
                            bgcolor: "primary.main",
                            minWidth: 410,
                            ...scrollbarSx,
                        }}
                    >
                        {preview}
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                height: "100%",
                overflowY: "hidden",
                rowGap: 1,
            }}
        >
            {header && (
                <Box sx={{ bgcolor: "primary.main", borderRadius: 3, border: 1, borderColor: "neutrals.30" }}>
                    {header}
                </Box>
            )}
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    height: "100%",
                    overflowY: "hidden",
                    columnGap: 2,
                    maxWidth: "100%",
                    minWidth: 1000,
                    borderRadius: 3,
                }}
            >
                <Box
                    sx={[
                        {
                            display: "flex",
                            flexDirection: "column",
                            py: 3,
                            overflowY: "auto",
                            width: "100%",
                            minWidth: 350,
                            ...scrollbarSx,
                        },
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        ...(Array.isArray(mainContentProps) ? mainContentProps : [mainContentProps]),
                    ]}
                >
                    <Box
                        sx={{
                            maxWidth: `max(calc(60% - ${leftAlignPadding} - 16px), 350px)`,
                            pl: leftAlignPadding,
                            width: "100%",
                        }}
                    >
                        {mainContent}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
