import { Plugin } from "unified";
import { visit } from "unist-util-visit";
import { Parent } from "unist";

export interface CitationNode {
    type: "citation";
    value: string;
    children: {
        type: "text";
        value: string;
    }[];
}

export const remarkCitationPlugin: Plugin = () => {
    return tree => {
        visit(tree, "text", (node: { value: string }, index: number | null, parent: Parent | null) => {
            const value = node.value;
            const citationRegex = /\[(\d{1,3})\]/g;
            let match;
            let lastIndex = 0;
            const nodes = [];

            while ((match = citationRegex.exec(value)) !== null) {
                if (match.index > lastIndex) {
                    const textValue = value.slice(lastIndex, match.index).replace(/\s+$/, "");
                    nodes.push({
                        type: "text",
                        value: textValue,
                    });
                }

                nodes.push({
                    type: "citation",
                    value: match[1],
                    children: [],
                    data: { hName: "citation" },
                });

                lastIndex = citationRegex.lastIndex;
            }

            if (lastIndex < value.length) {
                nodes.push({
                    type: "text",
                    value: value.slice(lastIndex),
                });
            }

            if (nodes.length > 0 && parent && typeof index === "number") {
                parent.children.splice(index, 1, ...nodes);
                return index + nodes.length;
            }
        });
    };
};
