import * as React from "react";
import { Box, IconButton, Input, Theme, SxProps, Tooltip } from "@mui/material";
import { ArrowUp, Paperclip } from "iconsax-react";
import { useTheme } from "@mui/material/styles";
import { getScrollbarSx } from "./scrollbarProps";

interface SearchInputProps {
    query: string;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onQuerySubmit: () => void;
    disabledReason: string | null;
    multiline?: boolean;
    onAttach?: () => void;
    sx?: SxProps<Theme>;
}

export const SearchInput: React.FC<SearchInputProps> = ({
    query,
    onQueryChange,
    onQuerySubmit,
    onAttach,
    disabledReason,
    multiline = false,
    sx,
}) => {
    const theme = useTheme();

    const handleKeyDown = React.useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key === "Enter" && !event.shiftKey) {
                event.preventDefault();
                onQuerySubmit();
            }
        },
        [onQuerySubmit],
    );

    const attachButton = (
        <IconButton
            size="small"
            onClick={onAttach}
            sx={{ ml: multiline ? -0.5 : 0 }}
            disabled
            disableFocusRipple
            disableRipple
        >
            <Paperclip size={24} color={theme.palette.neutrals[80]} />
        </IconButton>
    );

    return (
        <Box
            sx={[
                {
                    display: "flex",
                    ...(multiline
                        ? {
                              flexDirection: "column",
                              alignItems: "stretch",
                          }
                        : {
                              alignItems: "center",
                          }),
                    bgcolor: "white",
                    borderRadius: 2,
                    border: 1,
                    borderColor: "neutrals.30",
                    "&:focus-within": {
                        borderColor: "neutrals.40",
                    },
                },
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
        >
            <Input
                autoFocus
                placeholder="Find answers"
                value={query}
                startAdornment={!multiline ? attachButton : undefined}
                onChange={onQueryChange}
                onKeyDown={handleKeyDown}
                fullWidth
                disableUnderline
                multiline={multiline}
                minRows={multiline ? 2 : undefined}
                maxRows={multiline ? 3 : undefined}
                sx={{
                    px: multiline ? 1.5 : undefined,
                    ...(multiline && { pt: 1.5 }),
                    height: multiline ? "auto" : 48,
                    ...(!multiline && { pr: 1 }),
                    "& .MuiInputBase-input": { ...getScrollbarSx("white") },
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: multiline ? 1 : undefined,
                    py: multiline ? 0.5 : undefined,
                }}
            >
                {multiline && attachButton}
                <Tooltip title={disabledReason ?? ""}>
                    <span>
                        <IconButton disabled={disabledReason != null} onClick={onQuerySubmit} size="small">
                            <Box
                                sx={{
                                    bgcolor: disabledReason != null ? "divider" : "secondary.main",
                                    borderRadius: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 24,
                                    height: 24,
                                }}
                            >
                                <ArrowUp size={24} color={theme.palette.common.white} />
                            </Box>
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>
        </Box>
    );
};
