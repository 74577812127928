import * as React from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { ReactComponent as Logo } from "../assets/logomark-primary.svg";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CitedSourceBox, CitedSourcePage } from "../shared/citedSourceBox";
import { BACKEND_URL } from "../backend-client/url";
import {
    coreChatRouterGetChat,
    CoreChatRouterRespondToUserResponse,
    coreFileStorageRouterGetFiles,
} from "../backend-client/generated";
import { SESSION_STORAGE_NEW_CHAT_QUERY } from "./sessionStorage";
import { chatQueryOptions, chatsQueryOptions, tenantQueryOptions } from "./queryOptions";
import {
    AssistantChatMessage,
    UserChatMessage,
    AssistantChatMessageSource,
    AssistantChatMessageSourcePage,
    ChatOutSchema,
    CoreFileStorageRouterGetFilesResponse,
} from "../backend-client/generated/types.gen";
import { ArrowDown2, ArrowRight2 } from "iconsax-react";
import { useBoolean } from "../utils/hooks";
import { CitedText } from "../shared/citedText";
import { PDFPreview } from "../search/pdfPreview";
import { Close } from "@mui/icons-material";
import { ChatSplitLayout } from "./chatSplitLayout";
import { ChatHeaderForTenant } from "./chatHeaderForTenant";
import { SelectedFile } from "./focusDocuments";
import { ChatInput } from "./chatInput";
import { DEFAULT_PERSONA_ID } from "../data/defaultPersona";
import { SelectedPersona } from "./selectedPersona";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";

const DEFAULT_SELECTED_PERSONA: SelectedPersona = {
    id: DEFAULT_PERSONA_ID,
    type: "tenant",
};

export const ChatView: React.FC = () => {
    return (
        <SidebarLayout>
            <ChatViewLayoutContent />
        </SidebarLayout>
    );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ChatViewLayoutContentProps {}

const ChatViewLayoutContent: React.FC<ChatViewLayoutContentProps> = () => {
    const { chatId } = useParams<{ chatId: string }>();
    const [selectedSourceInfo, setSelectedSourceInfo] = React.useState<
        { systemMessageIdx: number; sourceIdx: number; pageIdx: number | undefined } | undefined
    >(undefined);
    const [query, setQuery] = React.useState<string>("");
    const [lastQuery, setLastQuery] = React.useState<string | undefined>(undefined);
    const [isStreaming, setIsStreaming] = React.useState(false);
    const [streamedResponse, setStreamedResponse] = React.useState<string>("");
    const [streamedSources, setStreamedSources] = React.useState<AssistantChatMessageSource[] | undefined>(undefined);
    const [searchParams, setSearchParams] = useSearchParams();
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const scrollPositionRef = React.useRef(0);

    const {
        data: chat,
        isLoading,
        isError,
    } = useQuery({
        ...chatQueryOptions(chatId ?? ""),
        refetchInterval: isStreaming ? false : undefined,
        refetchOnWindowFocus: !isStreaming,
    });

    const queryClient = useQueryClient();

    const handleQueryChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    }, []);

    const handleFetchResponse = React.useCallback(
        async (submittedQuery: string) => {
            if (chatId == null || submittedQuery.trim().length === 0) {
                return;
            }

            setIsStreaming(true);
            setStreamedResponse("");
            setStreamedSources(undefined);
            setLastQuery(submittedQuery);

            const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
            if (token == null) {
                console.error("No auth token found");
                setIsStreaming(false);
                return;
            }

            const url = `${BACKEND_URL}/api/chat/respond/${chatId}`;
            const abortController = new AbortController();

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ query: submittedQuery }),
                    signal: abortController.signal,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body.getReader();
                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = "";

                const processChunk = async ({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> => {
                    if (done) {
                        if (textBuffer.length > 0) {
                            setStreamedResponse(prev => prev + textBuffer);
                        }
                        return;
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? "";

                        for (const line of lines) {
                            if (line.trim()) {
                                try {
                                    const data = JSON.parse(line) as CoreChatRouterRespondToUserResponse;
                                    switch (data.type) {
                                        case "r_chnk": {
                                            textBuffer += data.data;
                                            const match = textBuffer.match(/(.*[\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                setStreamedResponse(prev => prev + completeText);
                                            }
                                            break;
                                        }
                                        case "sources":
                                            setStreamedSources(
                                                data.data.map(source => ({
                                                    name: source.file_name,
                                                    pages: source.pages,
                                                    file_type: source.file_type,
                                                    blob_name: source.blob_name,
                                                    is_user_file: source.is_user_file,
                                                })),
                                            );
                                            break;
                                        case "status":
                                            if (data.data === "completed" || data.data === "no-results") {
                                                setIsStreaming(false);
                                            }
                                            break;
                                        case "citations": {
                                            const usedRanks = new Set(data.data);
                                            setStreamedSources(prev =>
                                                prev != null
                                                    ? prev
                                                          .map(s => ({
                                                              ...s,
                                                              pages: s.pages?.filter(p => usedRanks.has(p.rank)) ?? [],
                                                          }))
                                                          .filter(s => s.pages.length > 0)
                                                    : undefined,
                                            );
                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", data.data);
                                            setIsStreaming(false);
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                };

                await reader.read().then(processChunk);
            } catch (error) {
                if (error instanceof Error) {
                    if (error.name === "AbortError") {
                        return;
                    }
                    console.error("Stream failed:", error);
                    setIsStreaming(false);
                }
            }

            return () => {
                abortController.abort();
            };
        },
        [chatId],
    );

    const handleSubmit = React.useCallback(() => {
        void handleFetchResponse(query);
        setQuery("");
    }, [handleFetchResponse, query]);

    const handleSelectSource = React.useCallback(
        (systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => {
            setSelectedSourceInfo({ systemMessageIdx, sourceIdx, pageIdx });
        },
        [],
    );

    const handleClosePreview = React.useCallback(() => {
        setSelectedSourceInfo(undefined);
    }, []);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSourceInfo(prev => {
            if (prev == null) return undefined;
            return { ...prev, pageIdx: newPage };
        });
    }, []);

    const disabledReason = React.useMemo(() => {
        if (query.trim().length === 0) return "Please enter a message";
        if (isStreaming) return "Sending message…";
        if (isLoading) return "Loading chat…";
        return null;
    }, [query, isStreaming, isLoading]);

    // Effect for handling new chat queries
    React.useEffect(() => {
        if (searchParams.has("new")) {
            const storedQuery = sessionStorage.getItem(SESSION_STORAGE_NEW_CHAT_QUERY);
            if (storedQuery != null && storedQuery.trim().length > 0) {
                void handleFetchResponse(storedQuery);
                sessionStorage.removeItem(SESSION_STORAGE_NEW_CHAT_QUERY);
                setSearchParams({}, { replace: true });
            }
        }
    }, [searchParams, handleFetchResponse, setSearchParams]);

    // Effect for handling streamed responses
    React.useEffect(() => {
        const updateChatData = async () => {
            if (!isStreaming && streamedResponse.trim().length > 0 && chatId != null) {
                const numMessagesBeforeCompletion = queryClient.getQueryData(chatQueryOptions(chatId).queryKey)
                    ?.messages.length;

                queryClient.setQueryData(chatQueryOptions(chatId).queryKey, oldData => {
                    const lastUserMessage: UserChatMessage = {
                        role: "user",
                        content: lastQuery ?? "",
                    };
                    const newAssistantMessage: AssistantChatMessage = {
                        role: "assistant",
                        content: streamedResponse,
                        sources: streamedSources ?? [],
                    };
                    if (oldData == null) return oldData;
                    return {
                        ...oldData,
                        messages: [...oldData.messages, lastUserMessage, newAssistantMessage],
                    };
                });

                setStreamedResponse("");
                setLastQuery(undefined);
                setStreamedSources(undefined);

                // We auto-set the name on the BE for the first message, so we need to refetch to get the updated name
                // and then update the local state
                if (numMessagesBeforeCompletion != null && numMessagesBeforeCompletion === 0) {
                    try {
                        const response = await coreChatRouterGetChat({ path: { chat_id: chatId } });
                        const refetchedChat = response.data;
                        if (refetchedChat == null) return;

                        queryClient.setQueryData(chatQueryOptions(chatId).queryKey, old => {
                            if (old == null) return old;
                            return { ...old, name: refetchedChat.name };
                        });

                        queryClient.setQueryData(chatsQueryOptions.queryKey, old => {
                            if (old == null) return old;
                            return old.map(c => (c.unique_id === chatId ? { ...c, name: refetchedChat.name } : c));
                        });
                    } catch (error) {
                        console.error("Failed to fetch chat:", error);
                    }
                }
            }
        };

        void updateChatData();
    }, [chatId, isStreaming, queryClient, streamedResponse, streamedSources, lastQuery]);

    const selectedSource = React.useMemo((): AssistantChatMessageSource | undefined => {
        if (selectedSourceInfo?.sourceIdx == null && selectedSourceInfo?.systemMessageIdx == null) return undefined;
        const message = chat?.messages[selectedSourceInfo.systemMessageIdx];
        if (message?.role !== "assistant") return undefined;
        return message.sources[selectedSourceInfo.sourceIdx];
    }, [chat?.messages, selectedSourceInfo?.systemMessageIdx, selectedSourceInfo?.sourceIdx]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRef.current && !isStreaming) {
            scrollPositionRef.current = scrollRef.current.scrollTop;
        }
    }, [isStreaming]);

    const isInitialLoad = React.useRef(true);

    React.useEffect(() => {
        if (
            scrollRef.current &&
            chat?.messages.length != null &&
            chat.messages.length > 0 &&
            isInitialLoad.current &&
            !scrollPositionRef.current
        ) {
            const element = scrollRef.current;
            element.scrollTop = element.scrollHeight;
            isInitialLoad.current = false;
        }
    }, [chat?.messages.length, scrollRef]);

    const scrollToBottom = React.useCallback((element: HTMLDivElement) => {
        requestAnimationFrame(() => {
            element.scrollTo({
                top: element.scrollHeight,
                behavior: "smooth",
            });
        });
    }, []);

    const debouncedScrollToBottom = React.useMemo(() => {
        let timeoutId: number;
        return (element: HTMLDivElement) => {
            if (timeoutId) {
                cancelAnimationFrame(timeoutId);
            }
            timeoutId = requestAnimationFrame(() => {
                scrollToBottom(element);
            });
        };
    }, [scrollToBottom]);

    React.useEffect(() => {
        if (isStreaming && scrollRef.current) {
            debouncedScrollToBottom(scrollRef.current);
        }
    }, [isStreaming, debouncedScrollToBottom, streamedResponse]);

    const patchedChat = React.useMemo(() => {
        if (isStreaming && lastQuery != null && chat != null) {
            const userMessage: UserChatMessage = { role: "user", content: lastQuery };
            return { ...chat, messages: [...chat.messages, userMessage] };
        }
        return chat;
    }, [lastQuery, chat, isStreaming]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    const initialData = React.useMemo((): CoreFileStorageRouterGetFilesResponse | null => {
        if (chat?.user_file_scope == null) return null;
        return chat.user_file_scope.map(fileId => ({
            unique_id: fileId,
            blob_name: fileId,
            file_name: fileId,
            file_type: "pdf",
            is_indexing: false,
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
        }));
    }, [chat?.user_file_scope]);

    const { data: files } = useQuery({
        queryKey: ["files", chat?.user_file_scope],
        queryFn: async () => {
            if (chat?.user_file_scope == null) return null;
            return coreFileStorageRouterGetFiles({
                body: { file_ids: chat.user_file_scope },
            }).then(res => res.data);
        },
        enabled: chat?.user_file_scope != null && chat.user_file_scope.length > 0,
        select: (data): SelectedFile[] =>
            data?.map<SelectedFile>(file => ({
                id: file.unique_id,
                name: file.file_name,
                fileType: file.file_type,
                isIndexing: file.is_indexing,
                createdAt: file.created_at,
                updatedAt: file.updated_at,
            })) ?? [],
        initialData,
    });

    const selectedPersona = React.useMemo((): SelectedPersona => {
        if (patchedChat?.user_persona != null) {
            return { id: patchedChat.user_persona.unique_id, type: "user" };
        }
        if (patchedChat?.tenant_persona != null) {
            return { id: patchedChat.tenant_persona.unique_id, type: "tenant" };
        }
        return DEFAULT_SELECTED_PERSONA;
    }, [patchedChat]);

    if (chatId == null) {
        return (
            <SidebarLayout>
                <Typography>No chat selected</Typography>
            </SidebarLayout>
        );
    }

    const mainContent = (
        <ChatViewContent
            // scrollRef={scrollRef}
            // onScroll={saveScrollPosition}
            chat={patchedChat}
            isLoading={isLoading}
            isError={isError}
            isStreaming={isStreaming}
            streamedResponse={streamedResponse}
            streamedSources={streamedSources}
            query={query}
            disabledReason={disabledReason}
            onQueryChange={handleQueryChange}
            onSubmit={handleSubmit}
            onSourceSelect={handleSelectSource}
        />
    );

    const header = (
        <ChatHeaderForTenant
            tenant={tenant}
            chatId={chatId}
            chatName={patchedChat?.name}
            isLoading={isLoading}
            // TODO: what happens if a fiel is deleted that was used in focus? We should still show
            focusedDocuments={files}
            onFocusedDocumentsChange={undefined}
            selectedPersona={selectedPersona}
            onSelectedPersonaChange={undefined}
        />
    );
    return (
        <ChatSplitLayout
            header={header}
            mainContent={mainContent}
            preview={
                selectedSource && (
                    <PreviewSection
                        source={selectedSource}
                        page={selectedSourceInfo?.pageIdx ?? 1}
                        onPageChange={handlePageChange}
                        onClose={handleClosePreview}
                    />
                )
            }
            mainContentProps={{
                bgcolor: "primary.main",
                borderRadius: 3,
                border: 1,
                borderColor: "neutrals.30",
            }}
            scrollRef={scrollRef}
            onScroll={saveScrollPosition}
        />
    );
};

const ChatViewContent: React.FC<{
    // scrollRef: React.RefObject<HTMLDivElement>;
    // onScroll: () => void;
    chat: ChatOutSchema | undefined;
    isLoading: boolean;
    isError: boolean;
    isStreaming: boolean;
    streamedResponse: string;
    streamedSources: AssistantChatMessageSource[] | undefined;
    query: string;
    disabledReason: string | null;
    onQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: () => void;
    onSourceSelect: (systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => void;
}> = ({
    // scrollRef,
    // onScroll,
    chat,
    isLoading,
    isError,
    isStreaming,
    streamedResponse,
    streamedSources,
    query,
    disabledReason,
    onQueryChange,
    onSubmit,
    onSourceSelect,
}) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "stretch",
                justifyContent: "space-between",
                flexGrow: 1,
                flexShrink: 1,
                minWidth: 0,
                borderRadius: 3,
                // mb: 2,
                // height: "100%",
                bgcolor: "primary.main",
            }}
        >
            <Box
                // ref={scrollRef}
                // onScroll={onScroll}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    px: 2,
                    pt: 2,
                }}
            >
                {isLoading ? (
                    <Typography>Loading chat…</Typography>
                ) : isError ? (
                    <Typography>Error loading chat</Typography>
                ) : (
                    <>
                        {chat?.messages.map((message, index) =>
                            message.role === "user" ? (
                                <UserMessage key={index} content={message.content} />
                            ) : (
                                <SystemMessage
                                    key={index}
                                    content={message.content}
                                    sources={message.sources}
                                    systemMessageIdx={index}
                                    onSourceSelect={onSourceSelect}
                                />
                            ),
                        )}
                        {isStreaming && (
                            <SystemMessage
                                content={streamedResponse}
                                sources={streamedSources}
                                systemMessageIdx={chat?.messages.length ?? 0}
                                onSourceSelect={undefined}
                            />
                        )}
                    </>
                )}
            </Box>
            <Box
                sx={{
                    position: "sticky",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    bgcolor: "primary.main",
                    pb: 2,
                    maxWidth: "100%",
                }}
            >
                <ChatInput
                    query={query}
                    onQueryChange={onQueryChange}
                    onQuerySubmit={onSubmit}
                    disabledReason={disabledReason}
                />
            </Box>
        </Box>
    );
};

// New components
const UserMessage: React.FC<{ content: string }> = ({ content }) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
        <Box
            sx={{
                maxWidth: "75%",
                px: 2,
                py: 1.5,
                borderRadius: 2,
                bgcolor: "surface.75",
            }}
        >
            <Typography whiteSpace="pre-wrap">{content}</Typography>
        </Box>
    </Box>
);

const SystemMessage: React.FC<{
    content: string;
    sources: AssistantChatMessageSource[] | undefined;
    systemMessageIdx: number;
    onSourceSelect: ((systemMessageIdx: number, sourceIdx: number, pageIdx: number | undefined) => void) | undefined;
}> = ({ content, sources, systemMessageIdx, onSourceSelect }) => {
    const { value: showSources, toggleValue: toggleShowSources } = useBoolean(true);

    const handleSourceSelect = React.useMemo(
        () =>
            onSourceSelect != null
                ? (sourceIdx: number, pageIdx: number | undefined) =>
                      onSourceSelect(systemMessageIdx, sourceIdx, pageIdx)
                : undefined,
        [onSourceSelect, systemMessageIdx],
    );

    const handleSelectSourceByRank = React.useCallback(
        (rank: number) => {
            if (onSourceSelect == null) {
                return;
            }
            const source = sources
                ?.flatMap((s, sourceIdx) => s.pages?.map(p => ({ sourceIdx, page: p })))
                .find(p => p?.page.rank === rank);
            if (source != null) {
                onSourceSelect(systemMessageIdx, source.sourceIdx, source.page.page);
            }
        },
        [onSourceSelect, sources, systemMessageIdx],
    );

    const theme = useTheme();
    const hasSources = sources != null && sources.length > 0;
    return (
        <Box sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}>
            <Box
                sx={{
                    maxWidth: "75%",
                    p: 2,
                    display: "flex",
                    overflowX: "hidden",
                    gap: 1,
                }}
            >
                <Box sx={{ display: "flex", alignItems: "start", mb: 1 }}>
                    <Logo style={{ width: 24, height: 24, marginRight: 8 }} />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", overflow: "hidden", gap: 1 }}>
                    {/* n relevant docs in muted text */}
                    {hasSources && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body1" color="neutrals.50">
                                {sources.length} relevant {sources.length === 1 ? "doc" : "docs"}
                            </Typography>
                            <IconButton size="small" onClick={toggleShowSources}>
                                {showSources ? (
                                    <ArrowDown2 size={12} color={theme.palette.neutrals[50]} />
                                ) : (
                                    <ArrowRight2 size={12} color={theme.palette.neutrals[50]} />
                                )}
                            </IconButton>
                        </Box>
                    )}
                    {showSources && hasSources && (
                        <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", overflowX: "auto", mt: 1 }}>
                            {sources?.map((source, idx) => (
                                // TODO: Add pages
                                <ChatCitedSourceBox
                                    key={idx}
                                    fileName={source.name}
                                    fileType={source.file_type}
                                    pages={source.pages ?? []}
                                    sourceIdx={idx}
                                    onSelect={handleSourceSelect}
                                />
                            ))}
                        </Box>
                    )}
                    <CitedText text={content} onCitationClick={handleSelectSourceByRank} />
                </Box>
            </Box>
        </Box>
    );
};

interface ChatCitedSourceBoxProps {
    fileName: string;
    fileType: AssistantChatMessageSource["file_type"];
    pages: AssistantChatMessageSourcePage[];
    sourceIdx: number;
    onSelect: ((sourceIdx: number, pageIdx: number | undefined) => void) | undefined;
}

const EMPTY_PAGES: CitedSourcePage[] = [];

const ChatCitedSourceBox: React.FC<ChatCitedSourceBoxProps> = ({ fileName, fileType, pages, sourceIdx, onSelect }) => {
    const handleSelect = React.useCallback(
        (pageIdx: number | undefined) => (onSelect != null ? onSelect(sourceIdx, pageIdx) : undefined),
        [onSelect, sourceIdx],
    );
    const citedSourcePages = React.useMemo((): CitedSourcePage[] => {
        return pages != null ? pages.map<CitedSourcePage>(p => ({ page: p.page, rank: p.rank })) : EMPTY_PAGES;
    }, [pages]);

    return (
        <CitedSourceBox
            fileName={fileName}
            fileType={fileType ?? undefined}
            pages={citedSourcePages}
            onSelect={handleSelect}
        />
    );
};

const PreviewSection: React.FC<{
    source: AssistantChatMessageSource;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    const previewSource = React.useMemo(() => {
        return {
            file_name: source.name,
            pages: source.pages ?? [],
            blob_name: source.blob_name ?? undefined,
            is_user_file: source.is_user_file ?? false,
        };
    }, [source]);

    return (
        <Box sx={{ height: "100%", py: 3, px: 5 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 2, columnGap: 2.5 }}>
                <Typography variant="h6" color="secondary.main" noWrap>
                    {source.name}
                </Typography>
                <IconButton onClick={onClose} size="small">
                    <Close sx={{ width: 20, height: 20 }} />
                </IconButton>
            </Box>
            <PDFPreview source={previewSource} page={page} onPageChange={onPageChange} />
        </Box>
    );
};
