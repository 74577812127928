import React from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { Filter } from "./filter";
import { Calendar2, DocumentText, Folder, User } from "iconsax-react";

interface FiltersProps {
    projects: string[];
    onProjectsChange: (projects: string[]) => void;
    sx?: SxProps<Theme>;
}

const ICON_SIZE = 16;

export const Filters: React.FC<FiltersProps> = ({ sx }) => {
    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[{ display: "flex", gap: 2 }, ...(Array.isArray(sx) ? sx : [sx])]}>
            <Filter
                icon={<Calendar2 size={ICON_SIZE} />}
                text="Anytime"
                content={undefined} // Empty content for now
            />
            <Filter
                icon={<User size={ICON_SIZE} />}
                text="Created by"
                content={undefined} // Empty content for now
            />
            <Filter
                icon={<DocumentText size={ICON_SIZE} />}
                text="File type"
                content={undefined} // Empty content for now
            />
            {/* <ProjectFilter selectedProjects={projects} onProjectsChange={onProjectsChange} /> */}
            <Filter
                icon={<Folder size={ICON_SIZE} />}
                text="Project"
                content={undefined} // Empty content for now
            />
        </Box>
    );
};
