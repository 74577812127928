import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    LinearProgress,
} from "@mui/material";
import { DocumentUpload, DocumentText, Clock } from "iconsax-react";
import { useCallback, useMemo, useState } from "react";
import { DocumentThumbnail } from "../search/documentThumbnail";
import { FocusedDocumentItem } from "./FocusedDocumentItem";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
    coreFileStorageRouterListRecentFiles,
    coreFileStorageRouterGetFiles,
} from "../backend-client/generated/services.gen";
import { getFilesQueryOptions } from "./queryOptions";
import { ButtonWithDisabledTooltip } from "../reusable/buttonWithDisabledTooltip";
import { SelectedFile } from "./focusDocuments";
import { DriveExplorer } from "./driveExplorer";
import { UploadTab } from "./UploadTab";
import { unique } from "../utils/arrays";
import { useUploadFileMutation } from "./uploadFileMutation";
import { UserUploadedFileSchema } from "../backend-client/generated";

type TabType = "upload" | "drive" | "recents";

interface FocusDocumentsDialogProps {
    open: boolean;
    onClose: () => void;
    onDone: (selectedFiles: SelectedFile[]) => void;
    selectedFiles?: SelectedFile[];
}

export const FocusDocumentsDialog: React.FC<FocusDocumentsDialogProps> = ({
    open,
    onClose,
    onDone,
    selectedFiles: initialSelectedFiles = [],
}) => {
    const queryClient = useQueryClient();
    // Initialize selected files with current focus documents
    const [selectedFiles, setSelectedFiles] = useState<SelectedFile[]>(initialSelectedFiles);

    // Update the Done button handler
    const handleDone = useCallback(() => {
        onDone(selectedFiles);
        onClose();
    }, [selectedFiles, onDone, onClose]);

    const [activeTab, setActiveTab] = useState<TabType>("drive");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadError, setUploadError] = useState<string | null>(null);
    const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);

    const tabs = useMemo(
        () => [
            { id: "drive" as const, label: "Drive", icon: <DocumentText size={20} /> },
            { id: "upload" as const, label: "Upload", icon: <DocumentUpload size={20} /> },
            { id: "recents" as const, label: "Recents", icon: <Clock size={20} /> },
        ],
        [],
    );

    // TODO: This is only recents in top-level
    const recentFilesQuery = useQuery({
        queryKey: ["files", "recents"],
        queryFn: () => coreFileStorageRouterListRecentFiles(),
    });

    const handleSelectDriveFile = useCallback((file: SelectedFile) => {
        setSelectedFiles(current => (current.some(f => f.id === file.id) ? current : [...current, file]));
    }, []);

    const { mutateAsync: uploadFile } = useUploadFileMutation();

    // Derive indexing files from selected files using useMemo
    const indexingFileIds = useMemo(
        () => selectedFiles.filter(file => file.isIndexing).map(file => file.id),
        [selectedFiles],
    );

    // Poll for indexing status
    const indexingStatusQuery = useQuery({
        queryKey: ["files", "indexing-status", indexingFileIds],
        queryFn: async () => {
            if (indexingFileIds.length === 0) return null;
            return coreFileStorageRouterGetFiles({
                body: { file_ids: indexingFileIds },
            });
        },
        enabled: indexingFileIds.length > 0,
        refetchInterval: query => {
            // If there are multiple files, refetch more frequently since they might be staggered
            if (indexingFileIds.length > 0) return 5000;

            if (query.state.dataUpdateCount === 0) return 5000;
            if (query.state.dataUpdateCount === 1) return 5000;
            if (query.state.dataUpdateCount === 2) return 10000;
            return 15000;
        },
    });

    // Use useEffect to handle the query's data changes
    React.useEffect(() => {
        const response = indexingStatusQuery.data;
        if (response == null) return;

        // If there are any files that are no longer indexing, update the files cache with new indexing status
        if (response.data?.some(f => !f.is_indexing) ?? false) {
            // Update the files for the current folder
            queryClient.setQueryData(getFilesQueryOptions(currentFolderId ?? undefined).queryKey, old => {
                if (old == null || old.data == null) return old;
                return {
                    ...old,
                    data: {
                        ...old.data,
                        files: old.data.files.map(file => {
                            const updatedFile = response.data?.find(f => f.unique_id === file.unique_id);
                            return updatedFile ?? file;
                        }),
                    },
                };
            });

            const loadedFolders = queryClient.getQueryData(getFilesQueryOptions(currentFolderId ?? undefined).queryKey)
                ?.data?.folders;

            // Update the files for all other folders
            for (const folder of loadedFolders ?? []) {
                queryClient.setQueryData(getFilesQueryOptions(folder.unique_id).queryKey, old => {
                    if (old == null || old.data == null) return old;
                    return {
                        ...old,
                        data: {
                            ...old.data,
                            files: old.data.files.map(f =>
                                f.unique_id === folder.unique_id
                                    ? (response.data?.find(f2 => f2.unique_id === f.unique_id) ?? f)
                                    : f,
                            ),
                        },
                    };
                });
            }
            // Update selected files with new indexing status
            setSelectedFiles(current =>
                current.map(file => {
                    const updatedFile = response.data?.find(f => f.unique_id === file.id);
                    return updatedFile ? { ...file, isIndexing: updatedFile.is_indexing } : file;
                }),
            );
        }
    }, [indexingStatusQuery.data, queryClient, currentFolderId]);

    const handleUploadFiles = useCallback(
        async (files: File[]) => {
            setIsUploading(true);
            setUploadProgress(0);
            setUploadError(null);

            try {
                const progressInterval = setInterval(() => {
                    setUploadProgress(prev => {
                        if (prev >= 95) return prev;
                        const increment = Math.max(0.5, (100 - prev) * 0.05);
                        return Math.min(95, prev + increment);
                    });
                }, 200);

                const uploadedFiles = await Promise.all(
                    // TODO: HACK since we don't have unique ids for files downstream
                    unique(files, file => file.name).map<Promise<SelectedFile>>(async file => {
                        const result = await uploadFile({
                            file,
                            lastModified: file.lastModified.toString(),
                        });
                        if (result.data == null) {
                            throw new Error("Failed to upload file");
                        }
                        return {
                            id: result.data.file.unique_id,
                            name: result.data.file.file_name,
                            created_at: result.data.file.created_at,
                            fileType: result.data.file.file_type,
                            isIndexing: result.data.file.is_indexing,
                        };
                    }),
                );

                clearInterval(progressInterval);
                setUploadProgress(100);
                setSelectedFiles(current => [...current, ...uploadedFiles]);
            } catch (error) {
                console.error("Error uploading files:", error);
                setUploadError("Failed to upload files. Please try again.");
            } finally {
                setIsUploading(false);
                if (indexingFileIds.length === 0) {
                    setUploadProgress(0);
                }
            }
        },
        [indexingFileIds.length, uploadFile],
    );

    const handleRemoveFile = useCallback((fileId: string) => {
        setSelectedFiles(files => files.filter(f => f.id !== fileId));
    }, []);

    const handleSelectRecent = useCallback((file: UserUploadedFileSchema) => {
        setSelectedFiles(current => {
            const exists = current.some(f => f.id === file.unique_id);
            if (exists) return current;
            return [
                ...current,
                {
                    id: file.unique_id,
                    name: file.file_name ?? file.blob_name,
                    created_at: file.created_at,
                    fileType: file.file_type,
                    isIndexing: file.is_indexing,
                },
            ];
        });
    }, []);

    const handleDeleteDriveFile = useCallback((fileId: string) => {
        setSelectedFiles(files =>
            files.find(f => f.id === fileId) != null ? files.filter(f => f.id !== fileId) : files,
        );
    }, []);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle sx={{ pb: 2 }}>Focus Documents</DialogTitle>
            <Box
                sx={{
                    px: 3,
                    display: "flex",
                    gap: 2,
                    borderBottom: 1,
                    borderColor: "neutrals.20",
                    justifyContent: "center",
                }}
            >
                {tabs.map(tab => (
                    <Box
                        key={tab.id}
                        onClick={() => setActiveTab(tab.id)}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            py: 1,
                            px: 2,
                            cursor: "pointer",
                            borderBottom: 2,
                            borderTopLeftRadius: 2,
                            borderTopRightRadius: 2,
                            borderColor: activeTab === tab.id ? "primary.main" : "transparent",
                            color: activeTab === tab.id ? "neutrals.80" : "neutrals.70",
                            bgcolor: activeTab === tab.id ? "surface.25" : "transparent",
                        }}
                    >
                        {tab.icon}
                        <Typography variant="body2">{tab.label}</Typography>
                    </Box>
                ))}
            </Box>

            <DialogContent
                sx={{
                    height: "50vh",
                    minHeight: 400,
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    gap: 3,
                }}
            >
                {activeTab === "upload" && (
                    <UploadTab
                        isUploading={isUploading}
                        uploadProgress={uploadProgress}
                        uploadError={uploadError}
                        indexingFileIds={indexingFileIds}
                        onUploadFiles={handleUploadFiles}
                    />
                )}

                {activeTab === "drive" && (
                    <DriveExplorer
                        currentFolderId={currentFolderId}
                        onSelectFile={handleSelectDriveFile}
                        onDeleteFile={handleDeleteDriveFile}
                        setCurrentFolderId={setCurrentFolderId}
                    />
                )}

                {activeTab === "recents" && (
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            gap: 2,
                            maxHeight: "50vh",
                            overflowY: "auto",
                            pr: 1,
                        }}
                    >
                        {recentFilesQuery.isLoading ? (
                            <Box sx={{ p: 2, textAlign: "center", gridColumn: "1 / -1" }}>
                                <LinearProgress />
                            </Box>
                        ) : (recentFilesQuery.data?.data?.files.length ?? 0) === 0 ? (
                            <Typography variant="body2" color="neutrals.80">
                                No recent documents
                            </Typography>
                        ) : (
                            recentFilesQuery.data?.data?.files.map(doc => (
                                <Box
                                    key={doc.unique_id}
                                    onClick={() => handleSelectRecent(doc)}
                                    sx={{
                                        display: "flex",
                                        gap: 1.5,
                                        p: 1,
                                        borderRadius: 1,
                                        cursor: "pointer",
                                        "&:hover": {
                                            bgcolor: "surface.25",
                                        },
                                    }}
                                >
                                    <DocumentThumbnail
                                        blobName={doc.blob_name}
                                        fileType={doc.file_type}
                                        // TODO: Change when focused dialog can show tenant files
                                        isUserFile={true}
                                        width={35}
                                        height={43}
                                    />
                                    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                overflow: "hidden",
                                                display: "-webkit-box",
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: "vertical",
                                                lineHeight: 1.2,
                                            }}
                                        >
                                            {doc.file_name ?? doc.blob_name}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))
                        )}
                    </Box>
                )}

                {selectedFiles.length > 0 && (
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: 2,
                            overflowY: "hidden",
                            pb: 2,
                            height: Math.max(180, selectedFiles.length * 60),
                        }}
                    >
                        <Typography variant="body2" color="neutrals.60">
                            {`Selected (${selectedFiles.length}) `}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, overflowY: "auto" }}>
                            {selectedFiles.map(file => (
                                <FocusedDocumentItem key={file.id} file={file} onRemove={handleRemoveFile} />
                            ))}
                        </Box>
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button variant="text" sx={{ color: "neutrals.80" }} size="small" onClick={onClose}>
                    Cancel
                </Button>
                <ButtonWithDisabledTooltip
                    variant="contained"
                    color="secondary"
                    size="small"
                    sx={{ borderRadius: 8 }}
                    onClick={handleDone}
                    disabledTooltip={
                        selectedFiles.length === 0
                            ? "No files selected"
                            : indexingFileIds.length > 0
                              ? "Some of your selected files are still processing"
                              : undefined
                    }
                >
                    Done
                </ButtonWithDisabledTooltip>
            </DialogActions>
        </Dialog>
    );
};
