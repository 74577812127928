import { Box } from "@mui/material";
import * as React from "react";

interface CitationProps {
    index: number;
    onClick?: (index: number) => void;
}

export const Citation: React.FC<CitationProps> = ({ index, onClick }) => {
    const handleClick = React.useCallback(() => {
        if (onClick) {
            onClick(index);
        }
    }, [index, onClick]);

    return (
        <Box
            component="span"
            onClick={handleClick}
            sx={{
                color: "info.main",
                cursor: onClick != null ? "pointer" : "default",
                position: "relative",
                top: -4,
                fontSize: "0.75rem",
                ml: 0.25,
                fontWeight: "bold",
                "&:hover": {
                    color: onClick != null ? "primary.dark" : "info.main",
                },
            }}
        >
            [{index + 1}]
        </Box>
    );
};
