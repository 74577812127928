import * as React from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import { Document, Thumbnail } from "react-pdf";

interface PageThumbnailProps {
    pageNumber: number;
    fileType: "pdf" | "docx" | "xlsx" | undefined;
    pdfUrl: string;
    isSelected: boolean;
    onClick: () => void;
}

const THUMBNAIL_WIDTH = 100;
const THUMBNAIL_HEIGHT = THUMBNAIL_WIDTH * 0.87;

export const PageThumbnail: React.FC<PageThumbnailProps> = React.memo(
    ({ pageNumber, fileType, pdfUrl, isSelected, onClick }: PageThumbnailProps) => {
        const [isReady, setIsReady] = React.useState(false);
        const handleItemClick = React.useCallback(() => {
            onClick();
        }, [onClick]);

        const onDocumentLoadSuccess = React.useCallback(() => {
            setIsReady(true);
        }, []);

        const skeleton = (
            <Skeleton
                variant="rectangular"
                width={THUMBNAIL_WIDTH}
                height={THUMBNAIL_HEIGHT}
                animation="wave"
                sx={{ bgcolor: "grey.100" }}
            />
        );

        return (
            <Box
                sx={{
                    cursor: "pointer",
                    borderRadius: 1,
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 1,
                    "&:hover": {
                        opacity: 0.8,
                    },
                }}
            >
                <Box
                    sx={{
                        width: 96,
                        height: 64,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "grey.50",
                        overflow: "hidden",
                        border: theme =>
                            isSelected
                                ? `2px solid ${theme.palette.selection.main}`
                                : `2px solid ${theme.palette.neutrals[20]}`,
                        borderRadius: 1,
                    }}
                >
                    <Document
                        className="highlight-block"
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={skeleton}
                    >
                        {isReady ? (
                            <Thumbnail
                                className="highlight-block"
                                pageNumber={pageNumber}
                                // renderTextLayer={false}
                                // renderAnnotationLayer={false}
                                error={
                                    <Typography sx={{ p: 2 }} variant="caption">
                                        Failed to load PDF
                                    </Typography>
                                }
                                loading={skeleton}
                                width={THUMBNAIL_WIDTH}
                                height={THUMBNAIL_HEIGHT}
                                onItemClick={handleItemClick}
                                // scale={0.8}
                            />
                        ) : (
                            skeleton
                        )}
                    </Document>
                </Box>
                <Box
                    sx={{
                        height: 20,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}
                >
                    <Typography
                        variant="caption"
                        sx={{
                            textAlign: "center",
                            color: "neutrals.60",
                        }}
                    >
                        {fileType === "xlsx" ? `Sheet ${pageNumber}` : `Page ${pageNumber}`}
                    </Typography>
                </Box>
            </Box>
        );
    },
);

PageThumbnail.displayName = "PageThumbnail";
