import * as React from "react";
import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import "./App.css";
import { QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Navigate, RouterProvider, RouterProviderProps, createBrowserRouter } from "react-router-dom";
import { SnackbarProviderProps, enqueueSnackbar } from "notistack";
import { AnswerGridSnackbarProvider } from "./answerGridSnackbarProvider";
import { H } from "highlight.run";
import { ErrorBoundary } from "@highlight-run/react";
import { HelmetProvider } from "react-helmet-async";
import { SearchHomeView } from "./search/searchHomeView";
import { SearchView } from "./search/searchView";
import { NewChatView } from "./chat/newChatView";
import { ProtectedRoute } from "./routing/protectedRoute";
import { LoginView } from "./auth/loginView";
import { ChatView } from "./chat/chatView";
import { BACKEND_URL } from "./backend-client/url";
import { client } from "./backend-client/generated/services.gen";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "./backend-client/authentication";
import { SharedChatView } from "./chat/sharedChatView";

const ANCHOR_ORIGIN: SnackbarProviderProps["anchorOrigin"] = {
    vertical: "bottom",
    horizontal: "right",
};

const rootElement = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = ReactDOM.createRoot(rootElement!);

client.setConfig({
    baseUrl: BACKEND_URL,
    throwOnError: true,
});

client.interceptors.request.use(config => {
    const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
    if (token != null) {
        config.headers.set("Authorization", `Bearer ${token}`);
    }
    return config;
});

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error, query) => {
            console.error(error);
            if (query.meta?.toastOnFailure == null || !query.meta.toastOnFailure) {
                return;
            }
            enqueueSnackbar(
                error.message != null
                    ? `An unexpected error occurred: ${error.message}`
                    : "An unexpected error occurred",
                {
                    variant: "error",
                },
            );
        },
    }),
});

const router: RouterProviderProps["router"] = createBrowserRouter([
    {
        path: "/home",
        element: <Navigate to="/" />,
    },

    {
        path: "/login",
        element: <LoginView />,
        ErrorBoundary: ErrorBoundary,
    },
    {
        element: <ProtectedRoute authenticationPath="/login" />,
        children: [
            {
                path: "/",
                element: <SearchHomeView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/chat",
                element: <NewChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/chat/:chatId",
                element: <ChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/search",
                element: <SearchView />,
                ErrorBoundary: ErrorBoundary,
            },
            {
                path: "/share/:chatId",
                element: <SharedChatView />,
                ErrorBoundary: ErrorBoundary,
            },
            // Add other protected routes here
        ],
    },
]);

const isProduction = process.env.NODE_ENV === "production";

H.init("ve6lm8re", {
    serviceName: "newton-frontend-app",
    tracingOrigins: isProduction
        ? [
              "https://newton-be.onrender.com",
              "newton-be.onrender.com",
              "newton-api.answergrid.ai",
              "https://newton-api.answergrid.ai",
          ]
        : ["localhost", "127.0.0.1"],
    environment: isProduction ? "production" : "development",
    disableSessionRecording: !isProduction,
    // TODO: Audit for any PII to remove
    privacySetting: "none",
    networkRecording: {
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [
            // insert full or partial urls that you don't want to record here
            // Out of the box, Highlight will not record these URLs (they can be safely removed):
            "https://www.googleapis.com/identitytoolkit",
            "https://securetoken.googleapis.com",
        ],
    },
});

const App: React.FC = () => {
    return (
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <AnswerGridSnackbarProvider anchorOrigin={ANCHOR_ORIGIN}>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </AnswerGridSnackbarProvider>
            </ThemeProvider>
        </HelmetProvider>
    );
};

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
