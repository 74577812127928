export const unique = <T>(arr: T[], getKey?: (item: T) => string): T[] => {
    if (getKey == null) {
        const seen = new Set<T>();
        return arr.filter(item => {
            if (seen.has(item)) return false;
            seen.add(item);
            return true;
        });
    }

    const seen = new Set<string>();
    return arr.filter(item => {
        const key = getKey(item);
        if (seen.has(key)) return false;
        seen.add(key);
        return true;
    });
};
