import { useTheme, useMediaQuery } from "@mui/material";
import { useMemo } from "react";

export const useLeftAlignPadding = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    return useMemo(() => {
        return isSmallScreen ? "25px" : isLargeScreen ? "100px" : "50px";
    }, [isSmallScreen, isLargeScreen]);
};
