import * as React from "react";
import { Box, Button, Typography, LinearProgress, Alert } from "@mui/material";
import { useCallback } from "react";

interface UploadTabProps {
    isUploading: boolean;
    uploadProgress: number;
    uploadError: string | null;
    indexingFileIds: string[];
    onUploadFiles: (files: File[]) => Promise<void>;
}

export const UploadTab: React.FC<UploadTabProps> = ({
    isUploading,
    uploadProgress,
    uploadError,
    indexingFileIds,
    onUploadFiles,
}) => {
    const handleDrop = useCallback(
        (e: React.DragEvent) => {
            e.preventDefault();
            const files = Array.from(e.dataTransfer.files).filter(
                file => file.name.toLowerCase().endsWith(".pdf") || file.name.toLowerCase().endsWith(".docx"),
            );
            if (files.length > 0) {
                void onUploadFiles(files);
            } else if (e.dataTransfer.files.length > 0) {
                console.error(`Uploaded ${e.dataTransfer.files.length} files but none were supported`);
            }
        },
        [onUploadFiles],
    );

    const handleDragOver = useCallback((e: React.DragEvent) => {
        e.preventDefault();
    }, []);

    const handleBrowseFileSystemForUpload = useCallback(() => {
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.accept = ".pdf,.docx";
        input.onchange = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (target.files) {
                const files = Array.from(target.files);
                void onUploadFiles(files);
            }
        };
        input.click();
    }, [onUploadFiles]);

    return (
        <Box
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            sx={{
                border: "2px dashed",
                borderColor: "divider",
                borderRadius: 2,
                p: 6,
                textAlign: "center",
                mb: 2,
                height: 160,
            }}
        >
            <Button
                variant="outlined"
                sx={{ mb: 1, color: "neutrals.80", borderColor: "neutrals.20" }}
                size="small"
                onClick={handleBrowseFileSystemForUpload}
                disabled={isUploading}
            >
                Browse
            </Button>
            <Typography variant="body2" color="text.secondary">
                or drag a doc here
            </Typography>
            {(isUploading || indexingFileIds.length > 0) && (
                <LinearProgress
                    variant="determinate"
                    value={Math.min(uploadProgress, 95)}
                    sx={{
                        mt: 2,
                        "& .MuiLinearProgress-bar": { bgcolor: "surface.100" },
                        bgcolor: "surface.50",
                    }}
                />
            )}
            {uploadError && (
                <Alert severity="error" sx={{ mt: 1, py: 0, maxWidth: "500px", mx: "auto" }}>
                    {uploadError}
                </Alert>
            )}
        </Box>
    );
};
