import { useMutation, useQueryClient } from "@tanstack/react-query";
import { coreFileStorageRouterUploadFile } from "../backend-client/generated/services.gen";

export const useUploadFileMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (params: { file: File; folderId?: string | null; lastModified?: string | null }) =>
            coreFileStorageRouterUploadFile({
                path: { filename: params.file.name },
                body: { file: params.file },
                query: { folder_id: params.folderId, last_modified: params.lastModified },
            }),
        onSuccess: () => {
            // Invalidate queries to refetch the file lists
            void queryClient.invalidateQueries({ queryKey: ["files"] });
        },
    });
};
