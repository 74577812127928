import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SidebarLayout } from "../sidebar/sidebarLayout";
import {
    Box,
    Typography,
    Skeleton,
    IconButton,
    Button,
    InputAdornment,
    TextField,
    useTheme,
    Theme,
    SxProps,
} from "@mui/material";
import { AssistantChatMessage, CoreSearchRouterSearchResponse, UserChatMessage } from "../backend-client/generated";
import { BACKEND_URL } from "../backend-client/url";
import { Close as CloseIcon } from "@mui/icons-material";
import { InfoCircle, Message, SearchNormal1 } from "iconsax-react";
import { Filters } from "./filters";
import { LOCAL_STORAGE_ACCESS_TOKEN } from "../backend-client/authentication";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { coreChatRouterCreateChat } from "../backend-client/generated/services.gen";
import { useSnackbar } from "notistack";
import { chatQueryOptions, tenantQueryOptions } from "../chat/queryOptions";
import { PersonAnswerSection } from "./personAnswerSection";
import { PersonData, Sources } from "./types";
import { Source } from "./types";
import { PDFPreview } from "./pdfPreview";
import { SourcesSection } from "./sourcesSection";
import { SearchViewHeader } from "./searchViewHeader";
import { TextAnswerSection } from "./textAnswerSection";
import { areSetsEqual } from "../utils/sets";
import { comparatorOnFields } from "../utils/comparators";
import { getSearchUrl } from "./url";
import { SplitLayout } from "../shared/splitLayout";
import { useLeftAlignPadding } from "../shared/useLeftAlignPadding";
import { faker } from "@faker-js/faker";
import { Avatar } from "@mui/material";
import { getScrollbarSx } from "../shared/scrollbarProps";

export const SearchView: React.FC = () => {
    return (
        <SidebarLayout>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    borderRadius: 3,
                    bgcolor: "surface.50",
                    rowGap: 1,
                    overflowY: "hidden",
                    overflowX: "hidden",
                    // position: "relative",
                }}
            >
                <SearchViewContent />
            </Box>
        </SidebarLayout>
    );
};

const SearchViewContent: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [sources, setSources] = React.useState<Sources | undefined>(undefined);
    const [ranksOfCitedPages, setRanksOfCitedPages] = React.useState<number[] | undefined>(undefined);
    const [aiAnswer, setAiAnswer] = React.useState<string | undefined>(undefined);
    const [hasNoResults, setHasNoResults] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isWaitingForSources, setIsWaitingForSources] = React.useState(true);
    const [isStreamingAnswer, setIsStreamingAnswer] = React.useState(false);
    const [selectedSource, setSelectedSource] = React.useState<{ idx: number; page: number | undefined } | undefined>(
        undefined,
    );
    const [personAnswer, setPersonAnswer] = React.useState<PersonData[] | undefined>(undefined);
    const [focusedChatSourceIdxs, setFocusedChatSourceIdxs] = React.useState<number[]>([]);
    const [isAnswerExpanded, setIsAnswerExpanded] = React.useState(false);

    const query = React.useMemo(() => {
        return new URLSearchParams(location.search).get("q") || "";
    }, [location.search]);

    const projects = React.useMemo(() => {
        return new URLSearchParams(location.search).get("projects")?.split(",") || [];
    }, [location.search]);

    const resetState = React.useCallback(() => {
        setSources(undefined);
        setRanksOfCitedPages(undefined);
        setAiAnswer(undefined);
        setPersonAnswer(undefined);
        setHasNoResults(false);
        setErrorMessage(undefined);
        setIsWaitingForSources(true);
        setSelectedSource(undefined);
    }, []);

    const handleSearch = React.useCallback(
        (newQuery: string, newProjects: string[]) => {
            resetState();
            navigate(getSearchUrl(newQuery, newProjects));
        },
        [navigate, resetState],
    );

    useSearchSSEStream({
        query,
        projects,
        setSources,
        setCitedSourceIds: setRanksOfCitedPages,
        setAiAnswer,
        setHasNoResults,
        setErrorMessage,
        setIsStreamingAnswer,
        setIsWaitingForSources,
        setPersonAnswer,
    });

    const sourcesWithCitedPages = React.useMemo((): Sources | undefined => {
        if (ranksOfCitedPages == null || sources == null) {
            return undefined;
        }
        return (
            sources
                // Remove sources that don't have any cited pages
                .filter(s => s.pages.some(p => ranksOfCitedPages.includes(p.rank)))
                // Remove pages that aren't cited in the file sources
                .map(s => ({ ...s, pages: s.pages.filter(p => ranksOfCitedPages.includes(p.rank)) }))
        );
    }, [ranksOfCitedPages, sources]);

    const selectedSourceData = React.useMemo(() => {
        if (sources == null || selectedSource?.idx == null) {
            return undefined;
        }
        return sources[selectedSource.idx];
    }, [sources, selectedSource?.idx]);

    const selectedSourcePage = React.useMemo(() => {
        return selectedSource?.page ?? 1;
    }, [selectedSource?.page]);

    const handlePageChange = React.useCallback((newPage: number) => {
        setSelectedSource(prev => {
            if (prev == null) {
                return undefined;
            }
            return { ...prev, page: newPage };
        });
    }, []);

    const handleClosePreview = React.useCallback(() => {
        setSelectedSource(undefined);
    }, []);

    const handleSelectSource = React.useCallback((idx: number, page?: number) => {
        setSelectedSource({ idx, page });
    }, []);

    // The sourcesWithCitedPages only contains the sources that have cited pages, so the indices
    // don't match up with the sources array. This logic finds the corresponding index in the
    // sources array and sets the selected source using that.
    const handleSelectCitedSource = React.useCallback(
        (idx: number, page?: number) => {
            const source = sourcesWithCitedPages?.[idx];
            const idxInAllSources = sources?.findIndex(s => s.file_name === source?.file_name);
            if (idxInAllSources == null) {
                return;
            }
            setSelectedSource({ idx: idxInAllSources, page });
        },
        [sources, sourcesWithCitedPages],
    );

    const patchedIsStreamingAnswer = isStreamingAnswer && errorMessage == null && !isWaitingForSources;

    const handleChatWithSources = React.useCallback(() => {
        if (focusedChatSourceIdxs.length === 0 || sources == null) {
            return;
        }
        navigate(`/chat?sources=${focusedChatSourceIdxs.map(idx => sources[idx].file_name).join(",")}`);
    }, [focusedChatSourceIdxs, sources, navigate]);

    const { data: tenant } = useQuery(tenantQueryOptions);

    if (errorMessage != null) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
                <InfoCircle size="32" color="red" />
                <Typography variant="body1" color="error" sx={{ ml: 2, maxWidth: "400px" }}>
                    {errorMessage}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <SplitLayout
                header={<SearchBarSection query={query} projects={projects} onSearch={handleSearch} />}
                mainContent={
                    isWaitingForSources ? (
                        <SkeletonAnswerSection />
                    ) : (
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                            {!hasNoResults && (
                                <AnswerSection
                                    aiAnswer={aiAnswer}
                                    isStreamingAnswer={patchedIsStreamingAnswer}
                                    citedSources={sourcesWithCitedPages}
                                    query={query}
                                    personAnswer={personAnswer}
                                    onSelectSource={handleSelectCitedSource}
                                    isExpanded={isAnswerExpanded}
                                    onExpandedChange={setIsAnswerExpanded}
                                />
                            )}
                            {/* TODO: HACKHACKHACK */}
                            {tenant?.can_chat_with_docs &&
                                (query.toLowerCase().includes("project") ||
                                    query.toLowerCase().includes("life expectancy")) && <ExpertsSection />}
                            <SourcesSection
                                sources={sources}
                                onSourceSelect={handleSelectSource}
                                focusedChatSourceIdxs={focusedChatSourceIdxs}
                                onFocusedChatSourceIdxsChange={setFocusedChatSourceIdxs}
                                selectedFileName={selectedSourceData?.file_name}
                                selectedPage={selectedSourcePage}
                            />
                        </Box>
                    )
                }
                preview={
                    selectedSourceData != null ? (
                        <PreviewSection
                            source={selectedSourceData}
                            page={selectedSourcePage}
                            onPageChange={handlePageChange}
                            onClose={handleClosePreview}
                        />
                    ) : undefined
                }
                mainContentProps={{
                    bgcolor: "primary.main",
                    borderRadius: 3,
                    border: 1,
                    borderColor: "neutrals.30",
                }}
            />
            {focusedChatSourceIdxs.length > 0 && (
                <Button
                    sx={{
                        position: "absolute",
                        width: "140px",
                        bottom: "10px",
                        left: 0,
                        transform: "translateX(calc(50vw - 70px))",
                        px: 1,
                        py: 0.25,
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}
                    color="secondary"
                    variant="contained"
                    onClick={handleChatWithSources}
                >
                    <Typography variant="caption">
                        Chat with{" "}
                        {focusedChatSourceIdxs.length === 1 ? "1 source" : `${focusedChatSourceIdxs.length} sources`}
                    </Typography>
                </Button>
            )}
        </>
    );
};

function constructSearchUrl() {
    return `${BACKEND_URL}/api/search/search`;
}

function useSearchSSEStream({
    query,
    projects,
    setSources,
    setCitedSourceIds,
    setAiAnswer,
    setHasNoResults,
    setErrorMessage,
    setIsWaitingForSources,
    setIsStreamingAnswer,
    setPersonAnswer,
}: {
    query: string;
    projects: string[];
    setSources: React.Dispatch<React.SetStateAction<Sources | undefined>>;
    setCitedSourceIds: React.Dispatch<React.SetStateAction<number[] | undefined>>;
    setAiAnswer: React.Dispatch<React.SetStateAction<string | undefined>>;
    setHasNoResults: React.Dispatch<React.SetStateAction<boolean>>;
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsWaitingForSources: React.Dispatch<React.SetStateAction<boolean>>;
    setIsStreamingAnswer: React.Dispatch<React.SetStateAction<boolean>>;
    setPersonAnswer: React.Dispatch<React.SetStateAction<PersonData[] | undefined>>;
}) {
    React.useEffect(() => {
        if (query.length === 0) {
            return;
        }

        setIsWaitingForSources(true); // Set loading when starting a new search

        const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
        if (token == null) {
            setErrorMessage("You must be logged in to search.");
            setIsWaitingForSources(false);
            return;
        }

        // Construct the endpoint URL. It should now be a POST endpoint.
        const url = constructSearchUrl();
        const abortController = new AbortController();

        // Start the fetch with POST
        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`, // If your backend expects auth
            },
            body: JSON.stringify({ query, filter_project: projects }),
            signal: abortController.signal,
        })
            .then(async response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                if (response.body == null) {
                    throw new Error("No body returned by the server.");
                }

                const reader = response.body?.getReader();
                if (!reader) {
                    throw new Error("No readable stream returned by the server.");
                }

                const decoder = new TextDecoder("utf-8");
                let buffer = "";
                let textBuffer = ""; // Add a text buffer for complete words

                async function processChunk({
                    done,
                    value,
                }: {
                    done: boolean;
                    value?: Uint8Array;
                }): Promise<Uint8Array | undefined> {
                    if (done) {
                        // Flush any remaining text when stream is done
                        if (textBuffer.length > 0) {
                            setAiAnswer(prevAnswer => (prevAnswer ?? "") + textBuffer);
                        }
                        return Promise.resolve(undefined);
                    }

                    if (value) {
                        buffer += decoder.decode(value, { stream: true });
                        const lines = buffer.split("\n");
                        buffer = lines.pop() ?? ""; // Keep the last partial line

                        for (let line of lines) {
                            line = line.trim();
                            if (line) {
                                try {
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    const data: CoreSearchRouterSearchResponse = JSON.parse(line);
                                    switch (data.type) {
                                        case "status":
                                            switch (data.data) {
                                                case "no-results":
                                                    setHasNoResults(true);
                                                    setIsWaitingForSources(false);
                                                    break;
                                                case "completed":
                                                    setIsStreamingAnswer(false);
                                                    break;
                                            }
                                            break;
                                        case "sources":
                                            setSources(data.data);
                                            setIsWaitingForSources(false);
                                            break;
                                        case "r_chnk": {
                                            setIsStreamingAnswer(true);
                                            // Buffer the text until we have complete words
                                            textBuffer += data.data;
                                            // Match up to the last space or citation closing bracket
                                            const match = textBuffer.match(/(.*[\s\]}])/);
                                            if (match) {
                                                const completeText = match[1];
                                                textBuffer = textBuffer.slice(completeText.length);
                                                setAiAnswer(prevAnswer => (prevAnswer ?? "") + completeText);
                                            }
                                            break;
                                        }
                                        case "error":
                                            console.error("Error from stream:", data.data);
                                            setErrorMessage(data.data);
                                            setIsStreamingAnswer(false);
                                            // Once an error is received, we might want to stop reading.
                                            abortController.abort();
                                            break;
                                        case "citations":
                                            setCitedSourceIds(data.data);
                                            break;
                                        case "people":
                                            if (data.data.length > 0) {
                                                setPersonAnswer(data.data);
                                                setIsWaitingForSources(false);
                                            }
                                            break;
                                    }
                                } catch (err) {
                                    console.error("Failed to parse JSON line:", err, line);
                                }
                            }
                        }
                    }

                    return reader.read().then(processChunk);
                }

                return reader.read().then(processChunk);
            })
            .catch((error: Error) => {
                if (error.name === "AbortError") {
                    // This is expected when the fetch is aborted.
                    return;
                }
                // Handle any fetch or parsing error
                console.error("Stream failed:", error);
                setErrorMessage("Stream failed");
                // setErrorMessage(error.message);
                setIsStreamingAnswer(false);
                setIsWaitingForSources(false);
            });

        return () => {
            // Cleanup: Abort the fetch and close the stream if the component unmounts
            abortController.abort();
        };
    }, [
        query,
        projects,
        setAiAnswer,
        setCitedSourceIds,
        setErrorMessage,
        setHasNoResults,
        setIsStreamingAnswer,
        setIsWaitingForSources,
        setSources,
        setPersonAnswer,
    ]);
}

const SearchBarSection: React.FC<{
    query: string;
    projects: string[];
    onSearch: (newQuery: string, newProjects: string[]) => void;
}> = ({ query, projects, onSearch }) => {
    const [inputValue, setInputValue] = React.useState(query);
    const [newProjects, setNewProjects] = React.useState(projects);

    const handleSubmit = React.useCallback(
        (event: React.FormEvent) => {
            event.preventDefault();
            const newProjectsSet = new Set(newProjects);
            const oldProjectsSet = new Set(projects);
            const hasQueryChanged = inputValue !== query;
            const hasProjectsChanged = !areSetsEqual(newProjectsSet, oldProjectsSet);
            if (inputValue.trim().length > 0 && (hasQueryChanged || hasProjectsChanged)) {
                onSearch(inputValue.trim(), Array.from(newProjectsSet).sort(comparatorOnFields(project => [project])));
            }
        },
        [newProjects, projects, inputValue, query, onSearch],
    );

    const handleClear = React.useCallback(() => {
        setInputValue("");
    }, []);

    const theme = useTheme();

    const handleChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    }, []);

    const leftAlignPadding = useLeftAlignPadding();

    return (
        <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: 2,
                bgcolor: "primary.main",
                py: 2,
                pl: leftAlignPadding,
                borderRadius: 3,
                minWidth: 1300,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 2,
                    alignItems: "center",
                    minWidth: 630,
                    maxWidth: "calc(60% - 200px)",
                }}
            >
                <TextField
                    fullWidth
                    variant="outlined"
                    value={inputValue}
                    size="small"
                    onChange={handleChange}
                    placeholder="Search…"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchNormal1 size={20} color={theme.palette.secondary.main} />
                            </InputAdornment>
                        ),
                        endAdornment: inputValue && (
                            <InputAdornment position="end">
                                <IconButton onClick={handleClear} edge="end" size="small">
                                    <CloseIcon sx={{ width: 16, height: 16 }} />
                                </IconButton>
                            </InputAdornment>
                        ),
                        sx: {
                            height: 48,
                        },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 8,
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "neutrals.50",
                                borderWidth: 1,
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                borderColor: "neutrals.50",
                                borderWidth: 1,
                            },
                        },
                        "& .MuiInputBase-input": {
                            color: "neutrals.80",
                        },
                    }}
                />
                <Filters projects={newProjects} onProjectsChange={setNewProjects} sx={{ alignSelf: "flex-start" }} />
            </Box>
        </Box>
    );
};

const AnswerSection: React.FC<{
    aiAnswer: string | undefined;
    isStreamingAnswer: boolean;
    citedSources: Sources | undefined;
    query: string;
    personAnswer: PersonData[] | undefined;
    onSelectSource: (idx: number, page: number | undefined) => void;
    sx?: SxProps<Theme>;
    isExpanded: boolean;
    onExpandedChange: (expanded: boolean) => void;
}> = ({
    aiAnswer,
    isStreamingAnswer,
    citedSources,
    query,
    personAnswer,
    onSelectSource,
    sx,
    isExpanded,
    onExpandedChange,
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const createChatMutation = useMutation({
        mutationFn: ({ messages, name }: { messages: Array<AssistantChatMessage | UserChatMessage>; name: string }) =>
            coreChatRouterCreateChat({
                throwOnError: true,
                body: { messages, name },
            }),
        onSuccess: data => {
            // Navigate to the newly created chat
            if (data.data.unique_id != null) {
                queryClient.setQueryData(chatQueryOptions(data.data.unique_id).queryKey, oldData => {
                    if (oldData == null) {
                        return data.data;
                    }
                    return {
                        ...oldData,
                        data: data.data,
                    };
                });
                navigate(`/chat/${data.data.unique_id}`);
            } else {
                enqueueSnackbar("Failed to create chat", { variant: "error" });
                console.error("No unique_id returned from create chat");
            }
        },
        onError: error => {
            enqueueSnackbar("Failed to create chat", { variant: "error" });
            console.error("Failed to create chat:", error);
        },
    });

    const handleFollowUp = React.useCallback(() => {
        if (aiAnswer == null) {
            return;
        }
        createChatMutation.mutate({
            messages: [
                { role: "user", content: query },
                {
                    role: "assistant",
                    content: aiAnswer,
                    sources:
                        citedSources?.map(source => ({
                            name: source.file_name,
                            pages: source.pages,
                            file_type: source.file_type,
                        })) ?? [],
                },
            ],
            name: `${query.charAt(0).toUpperCase() + query.slice(1, 40) + (query.length > 40 ? "…" : "")} - Follow-up`,
        });
    }, [aiAnswer, citedSources, query, createChatMutation]);

    if (aiAnswer == null && personAnswer == null) {
        return null;
    }

    return (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        <Box sx={[...(Array.isArray(sx) ? sx : [sx])]}>
            <SearchViewHeader
                text="Answer"
                tooltip="This answer was generated by an AI based on the sources that matched your search query."
                action={
                    !isStreamingAnswer && personAnswer == null ? (
                        <Button
                            variant="outlined"
                            color="secondary"
                            startIcon={<Message size={16} />}
                            onClick={handleFollowUp}
                            disabled={createChatMutation.isPending}
                            size="small"
                            sx={{
                                borderRadius: 12,
                                borderColor: "neutrals.30",
                                alignSelf: "flex-start",
                                bgcolor: "white",
                            }}
                        >
                            <Typography variant="caption" fontWeight={500}>
                                {createChatMutation.isPending ? "Creating chat…" : "Follow up"}
                            </Typography>
                        </Button>
                    ) : undefined
                }
            />

            {personAnswer != null ? (
                <PersonAnswerSection
                    person={personAnswer[0]}
                    citedSources={citedSources}
                    aiAnswer={aiAnswer}
                    onSelectSource={onSelectSource}
                />
            ) : (
                <TextAnswerSection
                    aiAnswer={aiAnswer}
                    citedSources={citedSources}
                    isStreamingAnswer={isStreamingAnswer}
                    onSelectSource={onSelectSource}
                    isExpanded={isExpanded}
                    onExpandedChange={onExpandedChange}
                />
            )}
        </Box>
    );
};

const SkeletonAnswerSection: React.FC = () => (
    <Box sx={{ mb: 3, width: "100%", flexGrow: 1 }}>
        <Skeleton variant="text" width="20%" height={32} sx={{ mb: 1 }} />
        <Skeleton variant="text" width="100%" height={64} />
    </Box>
);

const PreviewSection: React.FC<{
    source: Source;
    page: number;
    onPageChange: (newPage: number) => void;
    onClose: () => void;
}> = ({ source, page, onPageChange, onClose }) => {
    return (
        <Box sx={{ height: "100%", py: 3, px: 5, borderRadius: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", columnGap: 2.5 }}>
                <SearchViewHeader text={source.file_name} tooltip="Preview the selected document" />
                <IconButton onClick={onClose} size="small" sx={{ mt: -1 }}>
                    <CloseIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
            </Box>
            <PDFPreview source={source} page={page} onPageChange={onPageChange} />
        </Box>
    );
};

interface ExpertCardProps {
    name: string;
    title: string;
    imageUrl: string;
}
const ExpertCard: React.FC<ExpertCardProps> = React.memo(({ name, title, imageUrl }: ExpertCardProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                bgcolor: "white",
                border: 1,
                borderColor: "neutrals.30",
                minWidth: 250,
                height: 64,
                overflow: "hidden",
            }}
        >
            <Avatar
                src={imageUrl}
                sx={{
                    width: 40,
                    height: 40,
                    mr: 2,
                    borderRadius: 1,
                }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflow: "hidden" }}>
                <Typography variant="subtitle2" fontWeight={500} noWrap sx={{ textOverflow: "ellipsis" }}>
                    {name}
                </Typography>
                <Typography variant="caption" color="text.secondary" noWrap sx={{ textOverflow: "ellipsis" }}>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
});
ExpertCard.displayName = "ExpertCard";

const FAKE_SEXES = Array(8)
    .fill(null)
    .map(() => faker.person.sexType());

const FAKE_NAMES = FAKE_SEXES.map(sex => faker.person.fullName({ sex }));
const FAKE_JOB_TITLES = FAKE_SEXES.map(() => faker.person.jobTitle());
const FAKE_IMAGES = FAKE_SEXES.map(sex =>
    sex === "male"
        ? `https://randomuser.me/api/portraits/men/${faker.number.int({ min: 1, max: 75 })}.jpg`
        : `https://randomuser.me/api/portraits/women/${faker.number.int({ min: 1, max: 75 })}.jpg`,
);

const ExpertsSection: React.FC = React.memo(() => {
    const experts = React.useMemo(() => {
        return FAKE_NAMES.map((name, index) => ({
            name,
            title: FAKE_JOB_TITLES[index],
            imageUrl: FAKE_IMAGES[index],
        }));
    }, []);

    return (
        <Box sx={{ display: "flex", flexDirection: "column", mt: 1, mb: 3, overflow: "hidden" }}>
            <SearchViewHeader text="Experts" tooltip="People who might be able to help with your query" />
            <Box
                sx={{
                    display: "flex",
                    gap: 2,
                    overflowX: "auto",
                    justifyContent: "space-between",
                    pb: 1,
                    ...getScrollbarSx("primary.main"),
                }}
            >
                {experts.map((expert, index) => (
                    <ExpertCard key={index} {...expert} />
                ))}
            </Box>
        </Box>
    );
});

ExpertsSection.displayName = "ExpertsSection";
