import * as React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    TextField,
    Grid,
    IconButton,
    Box,
    useTheme,
    Typography,
    Button,
    DialogActions,
    lighten,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { PersonaCard } from "./PersonaCard";
import { SearchNormal, ArrowLeft2, TickCircle, Copy, Edit2, Trash } from "iconsax-react";
import { getScrollbarSx } from "../shared/scrollbarProps";
import { PersonaListSchema } from "../backend-client/generated";
import { personasQueryOptions } from "./queryOptions";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
    corePersonaRouterCreatePersona,
    corePersonaRouterDeletePersona,
    corePersonaRouterUpdatePersona,
} from "../backend-client/generated/services.gen";
import { SelectedPersona } from "./selectedPersona";
import { DEFAULT_PERSONA, DEFAULT_PERSONA_ID } from "../data/defaultPersona";

interface PersonaPickerDialogProps {
    open: boolean;
    selectedPersona: SelectedPersona;
    onClose: () => void;
    setSelectedPersona: React.Dispatch<React.SetStateAction<SelectedPersona>> | undefined;
}

const scrollbarSx = getScrollbarSx("background.main");

type EditablePersona = {
    id: string;
    name: string;
    systemPrompt: string;
    description: string | undefined;
    useDefaultPromptInAddition: boolean;
    mode: "editing" | "duplicating";
};

export const PersonaPickerDialog: React.FC<PersonaPickerDialogProps> = React.memo(
    ({ open, selectedPersona, setSelectedPersona, onClose }: PersonaPickerDialogProps) => {
        const [searchQuery, setSearchQuery] = React.useState("");
        const [previewPersona, setPreviewPersona] = React.useState<PersonaListSchema | null>(null);
        const [editablePersona, setEditablePersona] = React.useState<EditablePersona | null>(null);

        const queryClient = useQueryClient();

        const { mutate: createPersonaMutation } = useMutation({
            mutationFn: (data: {
                name: string;
                systemPrompt: string;
                description?: string;
                useDefaultPromptInAddition: boolean;
            }) =>
                corePersonaRouterCreatePersona({
                    body: {
                        name: data.name,
                        prompt: data.systemPrompt,
                        description: data.description,
                        use_default_prompt_in_addition: data.useDefaultPromptInAddition,
                    },
                }),
            onSuccess: response => {
                queryClient.setQueryData<PersonaListSchema[]>(["personas"], oldData => {
                    const newPersona = response.data;
                    if (oldData == null || newPersona == null) return oldData;
                    const typedNewPersona: PersonaListSchema = {
                        ...newPersona,
                        type: "user" as const,
                    };
                    return [
                        ...oldData.filter(p => p.unique_id !== DEFAULT_PERSONA_ID),
                        typedNewPersona,
                        DEFAULT_PERSONA,
                    ];
                });
            },
        });

        const { mutate: updatePersonaMutation } = useMutation({
            mutationFn: (data: {
                id: string;
                name: string;
                systemPrompt: string;
                description?: string;
                useDefaultPromptInAddition: boolean;
            }) =>
                corePersonaRouterUpdatePersona({
                    path: { persona_id: data.id },
                    body: {
                        name: data.name,
                        prompt: data.systemPrompt,
                        description: data.description,
                        use_default_prompt_in_addition: data.useDefaultPromptInAddition,
                    },
                }),
            onSuccess: (response, variables) => {
                queryClient.setQueryData<PersonaListSchema[]>(["personas"], oldData => {
                    if (oldData == null || response.data == null) return oldData;
                    return oldData.map(persona =>
                        persona.unique_id === variables.id ? { ...response.data, type: "user" as const } : persona,
                    );
                });
            },
        });

        const { mutate: deletePersonaMutation } = useMutation({
            mutationFn: (id: string) => corePersonaRouterDeletePersona({ path: { persona_id: id } }),
            onSuccess: (_, deletedId) => {
                queryClient.setQueryData<PersonaListSchema[]>(["personas"], oldData => {
                    if (!oldData) return oldData;
                    return oldData.filter(persona => persona.unique_id !== deletedId);
                });
            },
        });

        const handleSearchChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchQuery(event.target.value);
        }, []);

        const { data: personas } = useQuery(personasQueryOptions);

        const filteredPersonas = React.useMemo(() => {
            return personas?.filter(
                persona =>
                    persona.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    persona.description?.toLowerCase().includes(searchQuery.toLowerCase()),
                // || persona.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())),
            );
        }, [personas, searchQuery]);

        const handleSelect = React.useCallback(
            (id: string, type: PersonaListSchema["type"]) => {
                setSelectedPersona?.({ id, type });
            },
            [setSelectedPersona],
        );

        const handleDuplicate = React.useCallback(
            (id: string) => {
                const persona = personas?.find(p => p.unique_id === id);
                if (persona) {
                    setEditablePersona({
                        id: crypto.randomUUID(),
                        name: `${persona.name} (Copy)`,
                        systemPrompt: persona.prompt,
                        description: persona.description ?? undefined,
                        useDefaultPromptInAddition: persona.use_default_prompt_in_addition,
                        mode: "duplicating",
                    });
                    setPreviewPersona(persona);
                }
            },
            [personas],
        );

        const handleEdit = React.useCallback(
            (id: string) => {
                const persona = personas?.find(p => p.unique_id === id);
                if (persona) {
                    setEditablePersona({
                        id: persona.unique_id,
                        name: persona.name,
                        systemPrompt: persona.prompt,
                        description: persona.description ?? undefined,
                        useDefaultPromptInAddition: persona.use_default_prompt_in_addition,
                        mode: "editing",
                    });
                    setPreviewPersona(persona);
                }
            },
            [personas],
        );

        const handleSaveAndSelect = React.useCallback(() => {
            if (editablePersona == null) return;

            if (editablePersona.mode !== "duplicating") {
                updatePersonaMutation(
                    {
                        id: editablePersona.id,
                        name: editablePersona.name,
                        systemPrompt: editablePersona.systemPrompt,
                        description: editablePersona.description,
                        useDefaultPromptInAddition: editablePersona.useDefaultPromptInAddition,
                    },
                    {
                        onSuccess: () => {
                            setEditablePersona(null);
                            setPreviewPersona(null);
                            setSelectedPersona?.({ id: editablePersona.id, type: "user" });
                        },
                    },
                );
            } else {
                createPersonaMutation(
                    {
                        name: editablePersona.name,
                        systemPrompt: editablePersona.systemPrompt,
                        description: editablePersona.description,
                        useDefaultPromptInAddition: editablePersona.useDefaultPromptInAddition,
                    },
                    {
                        onSuccess: data => {
                            setEditablePersona(null);
                            if (data.data) {
                                setPreviewPersona({ ...data.data, type: "user" });
                            }
                            if (setSelectedPersona && data.data?.unique_id != null) {
                                setSelectedPersona({ id: data.data.unique_id, type: "user" });
                            }
                        },
                    },
                );
            }
        }, [createPersonaMutation, updatePersonaMutation, editablePersona, setSelectedPersona]);

        const handlePreview = React.useCallback(
            (id: string) => {
                const persona = personas?.find(p => p.unique_id === id);
                setPreviewPersona(persona || null);
            },
            [personas],
        );

        const handleBackToList = React.useCallback(() => {
            setPreviewPersona(null);
            setEditablePersona(null);
        }, []);

        const theme = useTheme();

        if (previewPersona != null) {
            const isEditing = editablePersona != null;
            const isPreviewSelected = selectedPersona.id === previewPersona.unique_id;

            return (
                <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                    <Box sx={{ px: 3, py: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                <IconButton onClick={handleBackToList} size="small">
                                    <ArrowLeft2 size={20} />
                                </IconButton>
                                {isEditing ? (
                                    <TextField
                                        value={editablePersona.name}
                                        onChange={e =>
                                            setEditablePersona(prev =>
                                                prev == null ? null : { ...prev, name: e.target.value },
                                            )
                                        }
                                        size="small"
                                        sx={{ minWidth: 300 }}
                                    />
                                ) : (
                                    <DialogTitle sx={{ p: 0, display: "flex", alignItems: "center" }}>
                                        {previewPersona.name}
                                        {/* {previewPersona.tags?.[0] && ( */}
                                        <Box
                                            sx={{
                                                ml: 1.5,
                                                px: 1,
                                                py: 0.5,
                                                bgcolor: "surface.75",
                                                borderRadius: 4,
                                                typography: "body2",
                                            }}
                                        >
                                            {previewPersona.unique_id === DEFAULT_PERSONA_ID
                                                ? "Default"
                                                : previewPersona.type === "tenant"
                                                  ? "Predefined"
                                                  : "Custom"}
                                        </Box>
                                        {/* )} */}
                                    </DialogTitle>
                                )}
                            </Box>
                            <IconButton onClick={onClose} size="small">
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </Box>

                        <DialogContent sx={{ p: 0, height: "70vh", overflowY: "auto" }}>
                            <Typography variant="body2" sx={{ mb: 0.5 }} noWrap fontWeight={500} color="neutrals.80">
                                Persona Prompt
                            </Typography>
                            {isEditing ? (
                                <TextField
                                    multiline
                                    fullWidth
                                    minRows={20}
                                    maxRows={20}
                                    value={editablePersona.systemPrompt}
                                    onChange={e =>
                                        setEditablePersona(prev =>
                                            prev == null ? null : { ...prev, systemPrompt: e.target.value },
                                        )
                                    }
                                />
                            ) : (
                                <Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }} color="neutrals.80">
                                    {previewPersona.prompt}
                                </Typography>
                            )}
                        </DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "flex-end", gap: 1, mt: 7, pb: 0 }}>
                            {isEditing ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleEdit(editablePersona.id)}
                                        size="small"
                                        sx={{ borderColor: "neutrals.30" }}
                                    >
                                        <Typography variant="caption" color="neutrals.80">
                                            Cancel
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleSaveAndSelect}
                                        startIcon={<TickCircle size={16} color="white" />}
                                        size="small"
                                        color="secondary"
                                    >
                                        <Typography variant="caption" color="white">
                                            {setSelectedPersona === undefined ? "Save" : "Save and Select"}
                                        </Typography>
                                    </Button>
                                </>
                            ) : (
                                <>
                                    {previewPersona.unique_id !== DEFAULT_PERSONA_ID && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    if (
                                                        window.confirm("Are you sure you want to delete this persona?")
                                                    ) {
                                                        deletePersonaMutation(previewPersona.unique_id);
                                                    }
                                                }}
                                                size="small"
                                                startIcon={<Trash size={16} color={theme.palette.error.main} />}
                                                sx={{
                                                    borderColor: "error.main",
                                                    "& .MuiButton-startIcon": { mr: 0.75 },
                                                    "&:hover": {
                                                        bgcolor: lighten(theme.palette.error.light, 0.8),
                                                        borderColor: "error.main",
                                                        color: "white",
                                                    },
                                                }}
                                            >
                                                <Typography variant="caption" color="error">
                                                    Delete
                                                </Typography>
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleEdit(previewPersona.unique_id)}
                                                size="small"
                                                startIcon={<Edit2 size={16} color={theme.palette.neutrals[80]} />}
                                                sx={{
                                                    borderColor: "neutrals.30",
                                                    "& .MuiButton-startIcon": { mr: 0.75 },
                                                    "&:hover": {
                                                        bgcolor: "action.hover",
                                                        borderColor: "neutrals.30",
                                                    },
                                                }}
                                            >
                                                <Typography variant="caption" color="neutrals.80">
                                                    Edit
                                                </Typography>
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleDuplicate(previewPersona.unique_id)}
                                        size="small"
                                        startIcon={<Copy size={16} color={theme.palette.neutrals[80]} />}
                                        sx={{
                                            borderColor: "neutrals.30",
                                            "& .MuiButton-startIcon": { mr: 0.75 },
                                            "&:hover": {
                                                bgcolor: "action.hover",
                                                borderColor: "neutrals.30",
                                            },
                                        }}
                                    >
                                        <Typography variant="caption" color="neutrals.80">
                                            Duplicate
                                        </Typography>
                                    </Button>
                                    {setSelectedPersona != null && (
                                        <Button
                                            variant={isPreviewSelected ? "outlined" : "contained"}
                                            onClick={() => handleSelect(previewPersona.unique_id, previewPersona.type)}
                                            size="small"
                                            color={isPreviewSelected ? "primary" : "secondary"}
                                            startIcon={
                                                <TickCircle
                                                    size={16}
                                                    variant={isPreviewSelected ? "Bold" : "Outline"}
                                                    color={isPreviewSelected ? theme.palette.secondary.main : "white"}
                                                />
                                            }
                                            sx={{
                                                borderColor: "neutrals.30",
                                                bgcolor: isPreviewSelected ? theme.palette.secondary.light : undefined,
                                                "&:hover": {
                                                    bgcolor: isPreviewSelected
                                                        ? theme.palette.secondary.light
                                                        : undefined,
                                                    borderColor: "neutrals.30",
                                                },
                                            }}
                                        >
                                            <Typography
                                                variant="caption"
                                                color={isPreviewSelected ? "neutrals.80" : "white"}
                                            >
                                                {isPreviewSelected ? "Active" : "Select"}
                                            </Typography>
                                        </Button>
                                    )}
                                </>
                            )}
                        </DialogActions>
                    </Box>
                </Dialog>
            );
        }

        return (
            <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth PaperProps={{ sx: { overflowY: "hidden" } }}>
                <Box sx={{ px: 3, py: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
                        <DialogTitle sx={{ p: 0 }}>Assistant Personas</DialogTitle>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <TextField
                        fullWidth
                        placeholder="Search personas"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        InputProps={{
                            startAdornment: (
                                <SearchNormal
                                    size={16}
                                    color={theme.palette.neutrals[80]}
                                    style={{ marginRight: theme.spacing(1) }}
                                />
                            ),
                        }}
                        size="small"
                        sx={{ mb: 2, borderColor: "neutrals.20" }}
                    />
                    <DialogContent sx={{ p: 0, height: "73vh", ...scrollbarSx }}>
                        <Grid container spacing={2}>
                            {filteredPersonas?.map(persona => (
                                <Grid item xs={12} sm={6} key={persona.unique_id}>
                                    <PersonaCard
                                        persona={persona}
                                        isSelected={
                                            selectedPersona.id === persona.unique_id &&
                                            selectedPersona.type === persona.type
                                        }
                                        onSelect={handleSelect}
                                        onDuplicate={handleDuplicate}
                                        onPreview={handlePreview}
                                        isReadOnly={setSelectedPersona === undefined}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </DialogContent>
                </Box>
            </Dialog>
        );
    },
);

PersonaPickerDialog.displayName = "PersonaPickerDialog";
